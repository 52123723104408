import './nav.css'
import Box from '@mui/material/Box'
import { Typography,Link } from '@mui/material'
import BClogo from './BClogo';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap'
import {useEffect, useState} from "react";
import {useMyContext } from './Context';
import { NavLink,useLocation } from "react-router-dom";


const Nav = () => {
    let pathname = useLocation().pathname;
    const context = useMyContext();
    useGSAP(()=>{
        if(context.state.MoveCompleted){
            gsap.to('.navLineOne',{
                duration:1,
                clipPath: "inset(0% 0% 0% 0%)",
            })
            gsap.to('.navLineTwo',{
                duration:1,
                clipPath: "inset(0% 0% 0% 0%)",
            })
        }
    },{dependencies: [context.state.MoveCompleted]})


    const navClick = ()=>{
        context.set.setNavOpen(!context.state.isNavOpen)
    }
    useGSAP(()=>{
        if(context.state.isNavOpen){
            const tl =gsap.timeline();
            tl.to('.navLineOne',{duration:1,rotation:-45, transformOrigin: "center center", y:'-300%',ease: "power2.out",})
            .to('.navLineTwo',{duration:1,rotation:45, transformOrigin: "center center",  y:'300%',ease: "power2.out",},"<")
            .to('.menu',{duration:1,transform:'translateX(0%)',ease: "power2.out",},"<")
            .fromTo('.menuText',{y:10,opacity:0,},{duration:1,y:0,opacity:1,stagger:0.2,ease: "power2.out",})
        }else{
            const tl =gsap.timeline();
            tl.to('.navLineOne',{duration:1,rotation:0, transformOrigin: "center center", y:'0%',ease: "power2.out",})
            .to('.navLineTwo',{duration:1,rotation:0, transformOrigin: "center center", y:'0%',ease: "power2.out",},"<")
            .to('.menu',{duration:1,transform:'translateX(100%)',ease: "power2.out",},"<")
            .to('.menuText',{duration:1,y:10,opacity:0,ease: "power2.out",stagger:0.2,})
        }
    },{dependencies: [context.state.isNavOpen]});
    return (
    <Box
    className='navContainer'
       sx={{
        boxSizing:'border-box',
        // display:'flex',
        width:'100vw',
        // justifyContent:'space-between',
        // alignItems: 'center',
        position:'fixed',
        padding:{
            xs:'5vw',
            sm:'2%',
        },
        zIndex:'99999',
       }}
    >

        <NavLink to={'/home'} onClick={()=>{
            if(context.state.isNavOpen){
                context.set.setNavOpen(!context.state.isNavOpen);
            }
        }}>
            <Box
                sx={{
                    width:'auto',
                    height:'5vh',
                    fill: '#ffffff',
                    zIndex:6,
                    position:'absolute',
                    left:{
                        xs:'5vw',
                        sm:'2%',
                    },
                    boxSizing:'border-box'
                }}
            >
                <BClogo color="#ffffff" animationType="type3" />
            </Box>
        </NavLink>

        <Box
            sx={{
            width:'auto',
            height:'5vh',
            zIndex:6,
            position:'absolute',
            right:{
                xs:'5vw',
                sm:'2%',
            },
            boxSizing:'border-box',
            }}
            onClick={()=>{navClick()}}
        >
            <svg height="100%" viewBox="0 0 30 30" fill="none" >
                <path fill="#fff" d="M0 11h30v1H0z" className="navLineTwo"/>
                <path fill="#fff" d="M0 17.213h30v1H0z" className="navLineOne"/>
            </svg>
        </Box>

        <Box 
        className="menu"
        sx={{
            backgroundColor:'background.default',
            position:'absolute',
            width:'100vw',
            height:'100vh',
            right:0,
            top:0,
            zIndex:5,
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignItems: 'center',
            transform:'translateX(100%)'
        }}
        >
            {/*路由链接*/}
            <NavLink to={'/home'} onClick={()=>{navClick()}}>
                <Typography
                 className='menuText'
                    color="#666666"
                    variant="h4"
                    sx={{
                        opacity:0,
                        margin:{
                            xs:'0 0 5vh 0',
                            sm:'0 0 2vh 0',
                        },
                    }}
                >
                    首页
                </Typography>
            </NavLink>

            <NavLink to={('/case/'==pathname.slice(0,pathname.length-1) )? pathname :'/case/1'} onClick={()=>{navClick()}}>
                <Typography
                 className='menuText'
                    color="#666666"
                    variant="h4"
                    sx={{
                        opacity:0,
                        margin:{
                            xs:'0 0 5vh 0',
                            sm:'0 0 2vh 0',
                        },
                    }}
                >
                    案例
                </Typography>
            </NavLink>
            <NavLink to={'/contactus'} onClick={()=>{navClick()}}>
                <Typography
                className='menuText'
                    color="#666666"
                    variant="h4"
                    sx={{
                        opacity:0,
                        margin:{
                            xs:'0 0 5vh 0',
                            sm:'0 0 2vh% 0',
                        },
                    }}
                >
                    联系我们
                </Typography>
            </NavLink>
        </Box>

    </Box>
    );
};
    
export default Nav;