import Box from '@mui/material/Box'
import { Typography,Link } from '@mui/material'
import WorkVideo from './img/digital_products_1.mp4'
// import Slide from './img/slideICON.svg'
import Avatar from '@mui/material/Avatar'
import {useMyContext } from '../Context';
import gsap from 'gsap'
import { useGSAP } from '@gsap/react';

const About = (zIndex) => {


    return (
        <Box
        className='HomeSection'
          sx={{
            zIndex:zIndex,
            width:'100%',
            height:'100%',
            position:'absolute',
            display:'flex',
            justifyContent: 'center',
            clipPath: 'inset(0% 0% 0% 0%)',
          }}
        >
            {/* <Avatar
                sx={{
                    width:'0.5em',
                    height:'auto',
                    borderRadius:'0',
                    objectFit: 'cover',
                    position:'fixed',
                    zIndex:'888',
                    bottom:'2em',
                }}
                alt=""
                src={Slide}
            ></Avatar> */}
            <Box 
            sx={{
                width:'0.5em',
                height:'auto',
                borderRadius:'0',
                objectFit: 'cover',
                position:'fixed',
                zIndex:'888',
                bottom:'2em',
            }}
            >
                <Slide />
            </Box>
             

            <video
                autoPlay
                loop
                playsInline
                style={{
                    position: 'absolute', 
                    width: '100%', 
                    height: '100%',
                    objectFit: 'cover',
                }}
            >
                <source src={WorkVideo} type="video/mp4" />
            </video>

            <Box
            className='HomeSectionOneText'
                sx={{
                boxSizing:'border-box',
                padding:'2%',
                textAlign:'center',
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                width:'100%',
                height:'100%',
                backgroundColor:'rgba(0, 0, 0, 0.4)',
                position: 'absolute', 
                opacity:1,
            }}
            >
                <Typography
                    color="text.primary"
                    variant="h2"
                    sx={{
                        textAlign:'center',
                    }}
                >
                    创新体验设计<br></br>&创建品牌数字资产
                </Typography>

            </Box>
        </Box>
    );
};
    
export default About;


function Slide(props) {
    useGSAP(()=>{
        gsap.to(".SlideC",{duration:1,y:"330%",repeat:-1,yoyo:true,})
        gsap.to(".SlideR",{duration:1,clipPath: 'inset(100% 0% 0% 0%)',opacity:0,repeat:-1,yoyo:true,})
    })

    return (
      <svg
        viewBox="0 0 10 45"
        {...props}
      >
       
          <path
            fill="url(#a)"
            fillOpacity={0.4}
            d="M0 5h10v40H0z"
            className="SlideR"
            style={{
                opacity:1,
                clipPath: 'inset(0% 0% 0% 0%)',
            }}
          />
          <circle cx={5} cy={5} r={5} fill="#fff" className="SlideC"  />
        
        <defs>
          <linearGradient
            id="a"
            x1={5}
            x2={5}
            y1={5}
            y2={45}
            className="a"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#fff" />
            <stop offset={1} stopColor="#fff" stopOpacity={0} />
          </linearGradient>
        </defs>
      </svg>
    )
  }
  

