import Home from "../homeComponents/Home";
import Work from "../Work";
import WorkPAGE from '../WorkPAGE';
import Test from '../components/Test';
import ContactUs from "../homeComponents/ContactUs";
import {Navigate} from "react-router-dom";

export default [
    {
        path:'/home',
        element:<Home />
    },
    {
        path:'/case/:id',
        element:<Work />
    },
    {
        path:'/',
        element:<Navigate to={"/home"} />
    },
    {
        path:'/detail/:id',
        element: <WorkPAGE />
    },
    {
        path:'/contactus',
        element:<ContactUs />
    }
]