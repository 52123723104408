import Box from '@mui/material/Box'
import {useMyContext } from '../Context';
import gsap from 'gsap'
import { useGSAP } from '@gsap/react';
import HomeSection1 from './HomeSection_one';
import HomeSection2 from './HomeSection_Two';
import HomeSection3 from './HomeSection_Three';
import HomeSection4 from './HomeSection_Four';
import { useEffect } from 'react';
import Observer from 'gsap/Observer';

gsap.registerPlugin(Observer);

function Home(props){

    const context = useMyContext();
    useGSAP(()=>{
        if(context.state.MoveCompleted){
            gsap.to('.HomeContainer',{duration:2,clipPath: 'inset(0% 0% 0% 0%)',ease: "power4.inOut",})
        }
    },{dependencies: [context.state.MoveCompleted]})
    let isDragging = false;



   
    useGSAP(()=>{
        let teamImg = gsap.utils.toArray(".teamImg");
        let serveMedia = gsap.utils.toArray(".serveMedia");
        let HomeSection = gsap.utils.toArray(".HomeSection");
        let wrap = gsap.utils.wrap(0, HomeSection.length);
        let animating;
        let currentIndex = -1;
        function gotoSection(index, direction){
            index = wrap(index); 
            animating = true;
            let fromTop = direction === -1,
            dFactor = fromTop ? -1 : 1,
            tl = gsap.timeline({
              defaults: { duration: 1.25, ease: "power2.inOut" },
              onComplete: () => animating = false
            });
            if (HomeSection[currentIndex]){
                if(currentIndex === 1 && index ===0){
                    tl
                    .to('.HomeSectionTwoText',{duration:1,y:120,ease: "power2.inOut"},"<")
                    .to('.teamSvgB',{duration:2,opacity:0,ease: "power2.inOut"},"<")
                    .to('.teamSvgC',{duration:2,opacity:0,ease: "power2.inOut"},"<")
                    .to('.sliderBackground',{duration:2,width:'0%'},"<")
                    .to('.rainbowSlider',{duration:2,opacity:0,boxShadow:'0px 15px 50px rgba(0, 0, 0, 0.0), inset 0px 1px 1px rgba(255, 255, 255, 0.2)',ease: "power2.inOut"},"<")
                    .to('.iconStraw',{duration:2,opacity:0,boxShadow:'0px 15px 50px rgba(0, 0, 0, 0.0), inset 0px 1px 1px rgba(255, 255, 255, 0.2)',ease: "power2.inOut"},"<")
                    .to(teamImg[0],{duration:2,clipPath: 'inset(49.9% 49.9% 50.1% 50.1% round 50px)',ease: "power2.inOut"},"<")
                    .to(teamImg[1],{duration:2,clipPath: 'inset(49.9% 49.9% 50.1% 50.1% round 50px)',ease: "power2.inOut"},"<")
                    .to(teamImg[2],{duration:2,clipPath: 'inset(49.9% 49.9% 50.1% 50.1% round 50px)',ease: "power2.inOut"},"<")
                    .to(teamImg[3],{duration:2,clipPath: 'inset(49.9% 49.9% 50.1% 50.1% round 50px)',ease: "power2.inOut"},"<")
                    .to('.sliderBackground',{duration:1,opacity:0},"<1")
                    .to(HomeSection[index],{duration:1, clipPath: 'inset(0% 0% 0% 0%)',ease: "power2.inOut" },"<")
                    .to('.HomeSectionOneText',{duration:1,opacity:1,y:0,ease: "power2.inOut"},"<.2")
               
                }else if(currentIndex === 2 && index ===1){
                    tl
                    .to('.HomeSectionThreeText',{duration:1,y:120,ease: "power2.inOut"},"<")
                    .to(serveMedia[0],{duration:1,left:context.fovsm?'calc(100vw - 10em)':'calc(100vw - 16em)',top:'110vh',ease: "power2.inOut"},"<")
                    .to(serveMedia[3],{duration:1,left:context.fovsm?'calc(100vw - 10em)':'calc(100vw - 16em)',top:'110vh',ease: "power2.inOut"},"<")
                    .to(serveMedia[4],{duration:1,left:context.fovsm?'calc(100vw - 10em)':'calc(100vw - 16em)',top:'110vh',ease: "power2.inOut"},"<")
                    .to(serveMedia[1],{duration:1,right:'0vw',top:'110vh',ease: "power2.inOut"},"<")
                    .to(serveMedia[2],{duration:1,right:'0vw',top:'110vh',ease: "power2.inOut"},"<")
                    .to(serveMedia[5],{duration:1,right:'0vw',top:'110vh',ease: "power2.inOut"},"<")
                    .set(HomeSection[2],{zIndex:97},"<1")
                    .to(HomeSection[1],{duration:2,backgroundColor:'#ffffff',opacity:1,ease: "power2.inOut"},"<-0.5")
                    .to('.HomeSectionTwoText',{duration:1,y:0,ease: "power2.inOut"},"<")
                    .to(teamImg[0],{duration:2,clipPath: 'inset(0% 0% 0% 0% round 0px)',ease: "power2.inOut"},"<")
                    .to(teamImg[1],{duration:2,clipPath: 'inset(0% 0% 0% 0% round 0px)',ease: "power2.inOut"},"<")
                    .to(teamImg[2],{duration:2,clipPath: 'inset(0% 0% 0% 0% round 0px)',ease: "power2.inOut"},"<")
                    .to(teamImg[3],{duration:2,clipPath: 'inset(0% 0% 0% 0% round 0px)',ease: "power2.inOut"},"<")
                    .to('.rainbowSlider',{duration:2,opacity:1,boxShadow:'0px 15px 50px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.2)',ease: "power2.inOut"},"<")
                    .to('.iconStraw',{duration:2,opacity:1,boxShadow:'0px 15px 50px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.2)',ease: "power2.inOut"},"<")
                    .to('.sliderBackground',{duration:1,opacity:1,ease: "power2.inOut"},"<")
                    .to('.sliderBackground',{duration:2,width:'100%',ease: "power2.inOut"},"<")
                    .to('.teamSvgB',{duration:2,opacity:1,ease: "power2.inOut"},"<")
                    .to('.teamSvgC',{duration:2,opacity:1,ease: "power2.inOut"},"<")

                }else if(currentIndex === 3 && index ===2){
                    tl
                    .to('.emailButton',{duration:1,y:10,opacity:0,ease: "power2.inOut"})
                    .to('.contactButton',{duration:1,y:10,opacity:0,ease: "power2.inOut"},"<0.2")
                    .to('.honor',{duration:1,y:50,opacity:0,ease: "power2.inOut"},"<")
                    .to(HomeSection[3],{duration:1,clipPath: 'inset(49.9% 0% 50.1% 0%)',ease: "power2.inOut"})
                    .to('.HomeSectionThreeText',{duration:1,y:0,ease: "power2.inOut"},"<")
                    .set(HomeSection[3],{zIndex:96})
                    
                }

            }
            if(currentIndex === 0 && index ===1){

                tl.to('.HomeSectionOneText',{duration:1,opacity:0,y:10,ease: "power2.inOut"})
                .to(HomeSection[currentIndex],{duration:1, clipPath: 'inset(49.9% 0% 50.1% 0%)',ease: "power2.inOut" },"<.2")
                .to(teamImg[0],{duration:2,clipPath: 'inset(0% 0% 0% 0% round 0px)',ease: "power2.inOut"},"<")
                .to(teamImg[1],{duration:2,clipPath: 'inset(0% 0% 0% 0% round 0px)',ease: "power2.inOut"},"<")
                .to(teamImg[2],{duration:2,clipPath: 'inset(0% 0% 0% 0% round 0px)',ease: "power2.inOut"},"<")
                .to(teamImg[3],{duration:2,clipPath: 'inset(0% 0% 0% 0% round 0px)',ease: "power2.inOut"},"<")
                .to('.rainbowSlider',{duration:2,opacity:1,boxShadow:'0px 15px 50px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.2)',ease: "power2.inOut"},"<")
                .to('.iconStraw',{duration:2,opacity:1,boxShadow:'0px 15px 50px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.2)',ease: "power2.inOut"},"<")
                .to('.sliderBackground',{duration:1,opacity:1,ease: "power2.inOut"},"<")
                .to('.sliderBackground',{duration:2,width:'100%',ease: "power2.inOut"},"<")
                .to('.teamSvgB',{duration:2,opacity:1,ease: "power2.inOut"},"<")
                .to('.teamSvgC',{duration:2,opacity:1,ease: "power2.inOut"},"<")
                .fromTo('.HomeSectionTwoText',{y:120},{duration:1,y:0,ease: "power2.inOut"},"<1")
                
            }else if(currentIndex === 1 && index ===2){
                tl
                .to('.HomeSectionTwoText',{duration:1,y:120,ease: "power2.inOut"},"<")
                .to('.teamSvgB',{duration:2,opacity:0,ease: "power2.inOut"},"<")
                .to('.teamSvgC',{duration:2,opacity:0,ease: "power2.inOut"},"<")
                .to('.sliderBackground',{duration:2,width:'0%'},"<")
                .to('.rainbowSlider',{duration:2,opacity:0,boxShadow:'0px 15px 50px rgba(0, 0, 0, 0.0), inset 0px 1px 1px rgba(255, 255, 255, 0.2)',ease: "power2.inOut"},"<")
                .to('.iconStraw',{duration:2,opacity:0,boxShadow:'0px 15px 50px rgba(0, 0, 0, 0.0), inset 0px 1px 1px rgba(255, 255, 255, 0.2)',ease: "power2.inOut"},"<")
                .to(teamImg[0],{duration:2,clipPath: 'inset(49.9% 49.9% 50.1% 50.1% round 50px)',ease: "power2.inOut"},"<")
                .to(teamImg[1],{duration:2,clipPath: 'inset(49.9% 49.9% 50.1% 50.1% round 50px)',ease: "power2.inOut"},"<")
                .to(teamImg[2],{duration:2,clipPath: 'inset(49.9% 49.9% 50.1% 50.1% round 50px)',ease: "power2.inOut"},"<")
                .to(teamImg[3],{duration:2,clipPath: 'inset(49.9% 49.9% 50.1% 50.1% round 50px)',ease: "power2.inOut"},"<")
                .to(HomeSection[1],{duration:2,backgroundColor:'#232629',opacity:0,ease: "power2.inOut"},"<")
                .fromTo('.HomeSectionThreeText',{y:120},{duration:1,y:0,ease: "power2.inOut"},"<1.5")
                .set(HomeSection[2],{zIndex:99},"<")
                .fromTo(serveMedia[0],{left:context.fovsm?'calc(100vw - 10em)':'calc(100vw - 16em)',top:'110vh'},{duration:1,left:'-1vw',top:'2vh',ease: "power2.inOut"},"<")
                .fromTo(serveMedia[3],{left:context.fovsm?'calc(100vw - 10em)':'calc(100vw - 16em)',top:'110vh'},{duration:1,left:'22vw',top:'40vh',ease: "power2.inOut"},"<")
                .fromTo(serveMedia[4],{left:context.fovsm?'calc(100vw - 10em)':'calc(100vw - 16em)',top:'110vh'},{duration:1,left:'-10vw',top:'55vh',ease: "power2.inOut"},"<")
                .fromTo(serveMedia[1],{right:'0vw',top:'110vh'},{duration:1,right:'8vw',top:'-1vh',ease: "power2.inOut"},"<")
                .fromTo(serveMedia[2],{right:'0vw',top:'110vh'},{duration:1,right:'-2vw',top:'26vh',ease: "power2.inOut"},"<")
                .fromTo(serveMedia[5],{right:'0vw',top:'110vh'},{duration:1,right:'10vw',top:'62vh',ease: "power2.inOut"},"<")
            }else if(currentIndex === 2 && index ===3){
                tl
                .to('.HomeSectionThreeText',{duration:1,y:120,ease: "power2.inOut"},"<")
                .set(HomeSection[3],{zIndex:99},"<")
                .to(HomeSection[3],{duration:1,clipPath: 'inset(0% 0% 0% 0%)',ease: "power2.inOut"})
                .fromTo('.emailButton',{y:10,opacity:0,},{duration:1,y:0,opacity:1,ease: "power2.inOut"},"<0.5")
                .fromTo('.contactButton',{y:10,opacity:0,},{duration:1,y:0,opacity:1,ease: "power2.inOut"},"<0.2")
                .fromTo('.honor',{y:50,opacity:0,},{duration:1,y:0,opacity:1,ease: "power2.inOut"},"<")
            }

            currentIndex = index; 
        }
        

        Observer.create({
            type: "wheel,touch",
            wheelSpeed: -1,
            // 在滚轮向下滚动时的回调函数
            onUp: () => {
                if(!isDragging){
                    if (!animating && currentIndex < HomeSection.length - 1) {
                        // 只有在不处于动画中且不在最后一个索引时才能向下滚动
                        gotoSection(currentIndex + 1, 1);
                    }
                }

            },
            
            // 在滚轮向上滚动时的回调函数
            onDown: () => {
                if(!isDragging){
                    if (!animating && currentIndex > 0) {
                        // 只有在不处于动画中且不在第一个索引时才能向上滚动
                        gotoSection(currentIndex - 1, -1);
                    }
                }
            },
            tolerance: 10,
            preventDefault: true
        });
        

        gotoSection(0, 1);
    })

    return(
        <Box
        className="HomeContainer"
            sx={{
                zIndex:'90',
                width:'100%',
                height:'100%',
                position:'relative',
                clipPath: 'inset(49.9% 0% 50.1% 0%)',
            }}
        >
            <HomeSection1 zIndex={99}/>
            <HomeSection2 zIndex={98}/>
            <HomeSection3 zIndex={97} openDraggable={()=>{isDragging=true;}} closeDraggable={()=>{isDragging=false;}}/>
            <HomeSection4 zIndex={96}/>
        </Box>
    )
}

export default Home;
