import Box from '@mui/material/Box'
import { Typography,Link } from '@mui/material'
import WorkVideo from './homeComponents/img/digital_products.mp4'
import Avatar from '@mui/material/Avatar'
import WorkLR from './img/LR.png'
// import team1 from './img/team1.png'
// import team2 from './img/team2.png'
// import team3 from './img/team3.png'
// import team4 from './img/team4.png'
import { NavLink,useParams } from "react-router-dom";
import {useState,useEffect} from "react";
import gsap from 'gsap'
// import {useMyContext } from './Context';
import {useMyContext} from "./Context";
import { useGSAP } from '@gsap/react';
import { Observer } from 'gsap/Observer';


//白鹭图片
import bailu1 from "./caseImgs/bailu/1.jpg";
import bailu2 from "./caseImgs/bailu/2.jpg";
import bailu3 from "./caseImgs/bailu/3.jpg";
import bailu4 from "./caseImgs/bailu/4.jpg";
import bailu5 from "./caseImgs/bailu/5.jpg";

//必趣图片
import bq1 from "./caseImgs/bq/1.jpg";
import bq2 from "./caseImgs/bq/2.jpg";
import bq3 from "./caseImgs/bq/3.jpg";
import bq4 from "./caseImgs/bq/4.jpg";
import bq5 from "./caseImgs/bq/5.jpg";
import bq6 from "./caseImgs/bq/6.jpg";
import bq7 from "./caseImgs/bq/7.jpg";
import bq8 from "./caseImgs/bq/8.jpg";
import bq9 from "./caseImgs/bq/9.jpg";
import bq10 from "./caseImgs/bq/10.jpg";
import bq11 from "./caseImgs/bq/11.jpg";
import bq12 from "./caseImgs/bq/12.jpg";
import bq13 from "./caseImgs/bq/13.jpg";
import bq14 from "./caseImgs/bq/14.jpg";
import bq15 from "./caseImgs/bq/15.jpg";

//中传媒
import zcm1 from "./caseImgs/zcm/1.jpg";
import zcm2 from "./caseImgs/zcm/2.jpg";
import zcm3 from "./caseImgs/zcm/3.jpg";
import zcm4 from "./caseImgs/zcm/4.jpg";
import zcm5 from "./caseImgs/zcm/5.jpg";
import zcm6 from "./caseImgs/zcm/6.jpg";
import zcm7 from "./caseImgs/zcm/7.jpg";

//la reponse
import la1 from "./caseImgs/laReponse/1.jpg";
import la2 from "./caseImgs/laReponse/2.jpg";
import la3 from "./caseImgs/laReponse/3.jpg";
import la4 from "./caseImgs/laReponse/4.jpg";
import la5 from "./caseImgs/laReponse/5.jpg";
import la6 from "./caseImgs/laReponse/6.jpg";
import la7 from "./caseImgs/laReponse/7.jpg";
import la8 from "./caseImgs/laReponse/8.jpg";
import la9 from "./caseImgs/laReponse/9.jpg";
import la10 from "./caseImgs/laReponse/10.jpg";
import la11 from "./caseImgs/laReponse/11.jpg";
import la12 from "./caseImgs/laReponse/12.jpg";
import la13 from "./caseImgs/laReponse/13.jpg";
import la14 from "./caseImgs/laReponse/14.jpg";
import la15 from "./caseImgs/laReponse/15.jpg";
import la16 from "./caseImgs/laReponse/16.jpg";
import la17 from "./caseImgs/laReponse/17.jpg";
import la18 from "./caseImgs/laReponse/18.jpg";
import la19 from "./caseImgs/laReponse/19.jpg";
import la20 from "./caseImgs/laReponse/20.jpg";
import la21 from "./caseImgs/laReponse/21.jpg";
import la22 from "./caseImgs/laReponse/22.jpg";

//phaetus
import pts1 from "./caseImgs/phaetus/1.jpg";
import pts2 from "./caseImgs/phaetus/2.jpg";
import pts3 from "./caseImgs/phaetus/3.jpg";
import pts4 from "./caseImgs/phaetus/4.jpg";
import pts5 from "./caseImgs/phaetus/5.jpg";
import pts6 from "./caseImgs/phaetus/6.jpg";
import pts7 from "./caseImgs/phaetus/7.jpg";
import pts8 from "./caseImgs/phaetus/8.jpg";
import pts9 from "./caseImgs/phaetus/9.jpg";
import pts10 from "./caseImgs/phaetus/10.jpg";
import pts11 from "./caseImgs/phaetus/11.jpg";
import pts12 from "./caseImgs/phaetus/12.jpg";
import pts13 from "./caseImgs/phaetus/13.jpg";
import pts14 from "./caseImgs/phaetus/14.jpg";
import pts15 from "./caseImgs/phaetus/15.jpg";
import pts16 from "./caseImgs/phaetus/16.jpg";
import pts17 from "./caseImgs/phaetus/17.jpg";
import pts18 from "./caseImgs/phaetus/18.jpg";
import pts19 from "./caseImgs/phaetus/19.jpg";
import pts20 from "./caseImgs/phaetus/20.jpg";
import pts21 from "./caseImgs/phaetus/21.jpg";
import pts22 from "./caseImgs/phaetus/22.jpg";

//phaetus10th
import ptsth1 from "./caseImgs/phaetus10th/1.jpg";
import ptsth2 from "./caseImgs/phaetus10th/2.jpg";
import ptsth3 from "./caseImgs/phaetus10th/3.jpg";
import ptsth4 from "./caseImgs/phaetus10th/4.jpg";
import ptsth5 from "./caseImgs/phaetus10th/5.jpg";
import ptsth6 from "./caseImgs/phaetus10th/6.jpg";
import ptsth7 from "./caseImgs/phaetus10th/7.jpg";
import ptsth8 from "./caseImgs/phaetus10th/8.jpg";
import ptsth9 from "./caseImgs/phaetus10th/9.jpg";

function WorkPAGE(){
    const context = useMyContext();
    let {id} = useParams();
    let datas = [
        {id:1,kw1:'白鹭资管品牌官网重塑',kw2:'白鹭资管品牌官网重塑',content:'白鹭资管是一家专注于二级市场、依靠数学和计算机科学进行量化投资与交易的对冲基金公司。',
            img:WorkLR
        },
        {id:2,kw1:'必趣科技品牌全案设计',kw2:'必趣科技品牌全案设计——BIQU',content:'深圳必趣科技有限公司是一家专业从事科技机器及配件设计、开发生产的专业制造商和出口商。旗下拥有BigTreTech主板、主控设备子品牌，我们针对其产品线进行了品牌的重新梳理与规划，调整了品牌结构，并全面升级品牌视觉形象'},
        {id:3,kw1:'中触媒新材料品牌国际官网设计',kw2:'中触媒新材料品牌国际官网设计',content:'中触媒新材料品牌国际官网设计'},
        {id:4,kw1:'瑞士护肤品牌La Réponse',kw2:'瑞士护肤品牌La Réponse | 品牌全案及艺术展设计',content:'为La Réponse品牌全新定制研发抗初老系列 “Re-Thesis”，2019年，将在上海CBE(美博会)推出Re-Thesis系列的9款单品，BCdesign受邀进行品牌发布的展会全案设计'},
        {id:5,kw1:'3D打印品牌数字设计——Phaetus',kw2:'3D打印品牌数字设计——Phaetus',content:'3D打印核心零部件品牌——Phaetus，我们对Phaetus进行了品牌的基建设计、响应式官网、活动运营设计、展会设计',img:WorkLR},
        {id:6,kw1:'Phaetus10周年活动-品牌互动营销页',kw2:'Phaetus10周年活动-品牌互动营销页',content:'Phaetus十周年纪念活动，由BCdesign策划、设计和开发。',img:WorkLR},
    ];

    let data = datas.find((obj)=>{
        return obj.id == id;
    })
    let {kw1,kw2,content,img} = data;
    // let [keyword1,setKeyword1] = useState(kw1);
    // let [keyword2,setKeyword2] = useState(kw2);
    // let [content1,setConent] = useState(content);

    function getData(id){
        let data = datas.find((obj)=>{
            return obj.id == id;
        })
        return data;
    }

    useGSAP(()=>{



        const tl =gsap.timeline();
        if(context.fovsm){
            gsap.set(".workPageFirst",{height:'100%'})
            gsap.set(".workText",{height:'0%'})
            // tl.to(".workPageFirst",{duration:2,height:'60%',ease: "power4.out",})
                tl.to(".workText",{duration:2,height:'40%',ease: "power4.out",},"<")
                .to(".textOP",{duration:1,opacity:1,ease: "power4.out",stagger:0.1,},"<")
                .from(".textOP",{duration:1,y:-50,ease: "power4.out",stagger:0.1,},"<")
        }else{
            gsap.set(".workPageFirst",{width:'100vw'})
            gsap.set(".workText",{width:'0%'})
            tl.to(".workPageFirst",{duration:2,width:'100%',ease: "power4.out",})
                .to(".workText",{duration:1,width:'30%',ease: "power4.out",},"<")
                .to(".textOP",{duration:1,opacity:1,ease: "power4.out",stagger:0.1,})
                .from(".textOP",{duration:1,y:-50,ease: "power4.out",stagger:0.1,},"<")
        }


        // function gotoSection(){
        //     console.log('work page')
        // }
        //
        //
        // Observer.create({
        //     type: "wheel,touch,pointer",
        //     wheelSpeed: -1,
        //     onDown: () => {
        //         console.log('down');
        //         gotoSection();
        //     },
        //     onUp: () => {
        //         console.log('up');
        //         gotoSection();
        //     },
        //     tolerance: 10,
        //     preventDefault: true
        // });

    });

    // useGSAP(()=>{
    //     if(context.state.MoveCompleted){
    //
    //         gsap.to('.WorkWrapper',{duration:2,clipPath: 'inset(0% 0% 0% 0%)',backgroundColor:'white',ease: "power4.inOut"});
    //     }
    // },{dependencies: [context.state.MoveCompleted]})

    useEffect(()=>{
        // let cases = gsap.utils.toArray(".Work");
        //
        // let wrap = gsap.utils.wrap(0, cases.length);

        let cases = gsap.utils.toArray(".bg");

        let wrap = gsap.utils.wrap(0, cases.length);

        // let currentIndex = context.fovmd ? -1 : 0;
        let currentIndex =  0;

        let images = document.querySelectorAll(".bg"),
            // outerWrappers = gsap.utils.toArray(".outer"),
            // innerWrappers = gsap.utils.toArray(".inner"),
            animating;

        function gotoSection(index, direction) {
            if(index>=cases.length){
                index = cases.length-1;
            }
            if(index<0){
                index=0;
            }
            if(index == currentIndex){
                return false;
            }
            index = wrap(index); // make sure it's valid
            animating = true;
            let fromTop = direction === -1,
                dFactor = fromTop ? -1 : 1,
                tl = gsap.timeline({
                    defaults: { duration: 1.25, ease: "power1.inOut" },
                    onComplete: () => animating = false
                });

            gsap.set(cases[index], { autoAlpha: 1, zIndex: 1 });
            tl.fromTo(images[index], { yPercent: 100 * dFactor }, { yPercent: 0 }, 0);
            tl.fromTo(images[currentIndex], { yPercent: 0 }, { yPercent: 100 * dFactor*-1 }, "<");

            // tl.to(".textOP",{duration:1,opacity:0,ease: "power4.out",stagger:0.1,onComplete:()=>{
            //         let data = getData(index+1);
            //         setKeyword1(data.kw1);
            //         setKeyword2(data.kw2);
            //         setConent(data.content);
            //     }},'<');        //文字消失动画
            // tl.to(".textOP",{duration:1,opacity:1,ease: "power4.out",stagger:0.1},);    //文字显示动画

            currentIndex = index;

        }

        function gotoSectionSM(index, direction) {
            if(index>=cases.length){
                index = cases.length-1;
            }
            if(index<0){
                index=0;
            }
            if(index == currentIndex){
                return false;
            }
            index = wrap(index); // make sure it's valid
            animating = true;
            let fromTop = direction === -1,
                dFactor = fromTop ? -1 : 1,
                tl = gsap.timeline({
                    defaults: { duration: 1.25, ease: "power1.inOut" },
                    onComplete: () => animating = false
                });

            gsap.set(cases[index], { autoAlpha: 1, zIndex: 1 });
            tl.fromTo(images[index], { xPercent: 100 * dFactor }, { xPercent: 0 }, 0);
            tl.fromTo(images[currentIndex], { xPercent: 0 }, { xPercent: 100 * dFactor*-1 }, "<");

            // tl.to(".textOP",{duration:1,opacity:0,ease: "power4.out",stagger:0.1,onComplete:()=>{
            //         let data = getData(index+1);
            //         setKeyword1(data.kw1);
            //         setKeyword2(data.kw2);
            //         setConent(data.content);
            //     }},'<');        //文字消失动画
            // tl.to(".textOP",{duration:1,opacity:1,ease: "power4.out",stagger:0.1},);    //文字显示动画

            currentIndex = index;

        }

        gsap.set(cases[0], { autoAlpha: 1, zIndex: 1 });


        if(context.fovsm){
            Observer.create({
                type: "wheel,touch,pointer",
                wheelSpeed: -1,
                onRight: () => !animating && gotoSectionSM(currentIndex - 1, -1),
                onLeft: () => !animating && gotoSectionSM(currentIndex + 1, 1),
                tolerance: 10,
                preventDefault: true
            });
        }else{
            Observer.create({
                type: "wheel,touch,pointer",
                wheelSpeed: -1,
                onDown: () => !animating && gotoSection(currentIndex - 1, -1),
                onUp: () => !animating && gotoSection(currentIndex + 1, 1),
                tolerance: 10,
                preventDefault: true
            });
        }




    },[]);

    return (

        <Box
          sx={{
            display:'flex',
            flexDirection:{
                xs:'column',
                sm:'row',
            },
            zIndex:'1000',
            width:'100%',
            height:'100%',
            position:'fixed',
          }}
        >
                <Box
                className="workText"
                  backgroundColor="background.paper"
                  sx={{
                    display:'flex',
                    boxSizing:'border-box',
                    flexDirection:'column',
                    justifyContent:'center',
                    padding:{
                        xs:'1em',
                        sm:'2%',
                    },
                    width:{
                        xs:'100%',
                        sm:'30%',
                    },
                    height:{
                        xs:'40%',
                        sm:'100vh',
                    },
                  }}
                >
                    <Typography
                    className='textOP'
                        color="text.primary"
                        variant="h3"
                        sx={{
                            opacity:0,
                        }}
                    >
                        {kw1}
                    </Typography>

                    {/*<Typography*/}
                    {/* className='textOP'*/}
                    {/*    color="text.h4"*/}
                    {/*    variant="h4"*/}
                    {/*    sx={{*/}
                    {/*        marginTop:'1em',*/}
                    {/*        opacity:0,*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {kw2}*/}
                    {/*</Typography>*/}

                    <Typography
                     className='textOP'
                        color="text.secondary"
                        variant="p"
                        sx={{
                            marginTop:'1em',
                            opacity:0,
                        }}
                    >
                        {content}
                    </Typography>
                </Box>

            <Box
                className='WorkContainer'
                sx={{
                    height:'100%',
                    width:'100%',
                    position:'relative',
                }}>
                    <Medias id={id} />

            </Box>

                {/*<Box*/}
                {/*    sx={{*/}
                {/*        right:{*/}
                {/*            sm:'1em'*/}
                {/*        },*/}
                {/*        bottom:{*/}
                {/*            xs:'0',*/}
                {/*            sm:'1em',*/}
                {/*        },*/}
                {/*        height:{*/}
                {/*            sm: '20vh',*/}
                {/*        },*/}
                {/*        position: 'absolute',*/}
                {/*        display:'flex',*/}

                {/*        padding:{*/}
                {/*            xs:'0',*/}
                {/*            sm:'0.2em',*/}
                {/*        },*/}
                {/*        boxSizing:'border-box',*/}
                {/*        width:{*/}
                {/*            xs:'100%',*/}
                {/*            sm:'0.8em',*/}
                {/*        },*/}
                {/*        borderRadius:{*/}
                {/*            xs:'0',*/}
                {/*            sm:'1em',*/}
                {/*        },*/}
                {/*        background: 'rgba(0, 0, 0, 0.2)',*/}
                {/*        webkitBackdropFilter: ' blur(10px)',*/}
                {/*        backdropFilter: 'blur(10px)',*/}
                {/*        zIndex:'2'*/}
                {/*    }}*/}
                {/*>*/}
                    {/*<Box*/}
                    {/*    className="bar_width"*/}
                    {/*    sx={{*/}
                    {/*        background: {*/}
                    {/*            xs:'linear-gradient(-90deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%)',*/}
                    {/*            sm:'linear-gradient(0deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%)',*/}
                    {/*        },*/}
                    {/*        borderRadius:{*/}
                    {/*            xs:'0',*/}
                    {/*            sm:'1em',*/}
                    {/*        },*/}
                    {/*        width:{*/}
                    {/*            xs:'10%',*/}
                    {/*            sm:'100%',*/}
                    {/*        },*/}
                    {/*        height:{*/}
                    {/*            xs:'0.3em',*/}
                    {/*            sm:'20%',*/}
                    {/*        },*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*</Box>*/}
                {/*</Box>*/}

                <Box
                    sx={{
                        right:'1em',
                        top:'1em',
                        position: 'absolute', 
                        width:{
                            xs:'1.6em',
                            sm:'2em',
                        },
                        height:{
                            xs:'1.6em',
                            sm:'2em',
                        },
                        borderRadius:{
                            xs:'100px',
                            sm:'100px',
                        },
                        padding:'0.2em 1em',
                        background: 'rgba(0, 0, 0, 0.2)',
                        webkitBackdropFilter: ' blur(10px)',
                        backdropFilter: 'blur(10px)',
                        zIndex:'2'
                    }}
                    onClick={()=>{}}
                >
                    <NavLink to={`/case/${id}`}>
                    <svg width="100%" height="100%" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30 32.3297L12.3516 49.978L10 47.6264L27.6483 29.978L10.022 12.3516L12.3736 10L30 27.6264L47.6264 10L49.978 12.3516L32.3516 29.978L50 47.6264L47.6483 49.978L30 32.3297Z" fill="white"/>
                    </svg>
                    </NavLink>
                </Box>

        </Box>
    );
};
    
export default WorkPAGE;


function Media(MediaFlag){
    let {id} = useParams();
    // let datas = [
    //     {id:1,img:team1},
    //     {id:2,img:team1},
    //     {id:3,img:team2},
    //     {id:4,img:team3},
    //     {id:5,img:team4},
    // ];
    // let data = datas.find((obj)=>{
    //     return obj.id == MediaFlag.idimg;
    // })
    // let {img} = data;

    // console.log(team1)

    if(MediaFlag.MediaFlag==='video'){
        return(
            <Box className={'bg'}>
                <video
                className='workPageFirst'
                    autoPlay
                    muted
                    loop
                    playsInline
                    style={{
                        width:{
                            xs:'100%',
                            sm:'100%',
                        },
                        height:{
                            xs:'60%',
                            sm:'auto',
                        },
                        borderRadius:'0',
                        objectFit: 'cover',
                    }}
                >
                    <source src={WorkVideo} type="video/mp4" />
                </video>
            </Box>
        )
    }else if(MediaFlag.MediaFlag==='img'){
        return(

                <Avatar
                className='bg workPageFirst'
                    sx={{
                        width:{
                            xs:'100%',
                            sm:'100%',
                        },
                        height:{
                            xs:'60%',
                            sm:'100%',
                        },
                        borderRadius:'0',
                        objectFit: 'cover',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        position:'absolute',
                    }}
                    alt=""
                    src={MediaFlag.imgsrc}
                />

        )
    }
    
}


function Medias(idimg){
    // console.log(idimg);

    // const context = useMyContext();


    // useEffect(() => {
    //     const tl =gsap.timeline();
    //     if(context.fovsm){
    //         gsap.set(".workPageFirst",{height:'100%'})
    //         gsap.set(".workText",{height:'0%'})
    //         // tl.to(".workPageFirst",{duration:2,height:'60%',ease: "power4.out",})
    //         tl.to(".workText",{duration:2,height:'40%',ease: "power4.out",},"<")
    //             .to(".textOP",{duration:1,opacity:1,ease: "power4.out",stagger:0.1,},"<")
    //             .from(".textOP",{duration:1,y:-50,ease: "power4.out",stagger:0.1,},"<")
    //     }else{
    //         gsap.set(".workPageFirst",{width:'100vw'})
    //         gsap.set(".workText",{width:'0%'})
    //         // tl.to(".workPageFirst",{duration:2,width:'100%',ease: "power4.out",})
    //         tl.to(".workText",{duration:1,width:'30%',ease: "power4.out",},"<")
    //             .to(".textOP",{duration:1,opacity:1,ease: "power4.out",stagger:0.1,})
    //             .from(".textOP",{duration:1,y:-50,ease: "power4.out",stagger:0.1,},"<")
    //     }
    // }, []);

    let {id} = idimg;
    let datas = [
        {id:1,imgs:[bailu1,bailu2,bailu3,bailu4,bailu5]},
        {id:2,imgs:[bq1,bq2,bq3,bq4,bq5,bq6,bq7,bq8,bq9,bq10,bq11,bq12,bq13,bq14,bq15]},
        {id:3,imgs:[zcm1,zcm2,zcm3,zcm4,zcm5,zcm6,zcm7]},
        {id:4,imgs:[la1,la2,la3,la4,la5,la6,la7,la8,la9,la10]},
        {id:5,imgs:[pts1,pts2,pts3,pts4,pts5,pts6,pts7,pts8,pts9,pts10]},
        {id:6,imgs:[ptsth1,ptsth2,ptsth3,ptsth4,ptsth5,ptsth6,ptsth7,ptsth8,ptsth9]},
    ];

    let data = datas.find((obj)=>{
        return obj.id == id;
    })
    let {imgs} = data;

    return (
        <Box
            className='Work'
            sx={{
                width:'100%',
                height:'100%',
                zIndex:0,
                position:'absolute'
            }}
        >

            {
                imgs.map((item,index)=>{
                    return (<Media MediaFlag={'img'} key={index+1} imgsrc={item} />);
                })
            }

        </Box>
    )

}