import Box from '@mui/material/Box'
import { Typography,Link } from '@mui/material'
import {useMyContext } from '../Context'
import Avatar from '@mui/material/Avatar'
import Photo from '../img/photo.svg'
import officeImg from "./img/office.jpg";
import meetingImg from "./img/meeting.jpg";
import discussImg from "./img/discuss.jpg";
import talkingImg from "./img/talking.jpg";
import logowallImg from "./img/logowall.jpg";
import photoroomImg from "./img/photoroom.jpg";

import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { useEffect,useRef } from "react";



gsap.registerPlugin(Draggable, InertiaPlugin);



const Serve = (zIndex) => {
    const context = useMyContext();
    const bottomTextRef = useRef();

    useEffect(()=>{
        Draggable.create(".serveMedia", {
            bounds: ".HomeSection",
            inertia: true,
            onDragStart:()=>{

                zIndex.openDraggable();
            },
            onDragEnd:()=>{
                zIndex.closeDraggable();
            },
            onPress:function(){
                let dragZindex = parseInt(this.target.style.zIndex);
                bottomTextRef.current.style.zIndex = dragZindex + 1;
            }

        }); 
    },[]);

    function over(event) {
        let serveMedia = gsap.utils.toArray(".serveMediaMask");
        serveMedia.forEach(e=>{
            if (e!== event.currentTarget.children[2]) {
                gsap.to(e, {duration: 0.5,opacity:0.3,ease: "power2.out",});
                gsap.to(event.currentTarget, {duration: 0.5, boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.5)',ease: "power2.out",});
            }
        })    
    }

    function leave() {
        let serveMedia = gsap.utils.toArray(".serveMediaMask");
        gsap.to(serveMedia,{duration:0.5,opacity:0,ease: "power2.out",}) 
 
    }

    return (

        <Box
        className='HomeSection'
          sx={{
            zIndex:zIndex,
            width:'100%',
            height:'100%',
            display:'flex',
            justifyContent:'center',
            backgroundColor:'background.default',
            position:'fixed',
          }}
        >
            <Box
            className='HomeSectionThreeText'
                ref={bottomTextRef}
                sx={{
                    width:{
                        xs:'90vw',
                        sm:'auto',
                    },
                    position:'fixed',
                    boxSizing:'border-box',
                    bottom:{
                        xs:'5vw',
                        sm:'2%',
                    },
                    padding:'0.8em',
                    borderRadius:'1em',
                    background: 'rgba(0, 0, 0, 0.2)',
                    webkitBackdropFilter: ' blur(10px)',
                    backdropFilter: 'blur(10px)',
                    zIndex:'999',
                }}
            >
                    <Typography
                        color="text.primary"
                        variant="p"
                    >
                        BC设计的任务是帮助我们的客户构思、设计、实施屡获殊荣的品牌视觉、数字网站、创意内容、应用程序，并建立数字资产
                    </Typography>
            </Box>
                <ServeMedia onMouseOver={(event)=>{over(event)}} onMouseLeave={()=>{leave()}} MediaId='M1' MediaFlag={'img'} MediaSrc={discussImg} MediaLeft={'-1vw'} MediaTop={'2vh'} MediaTransform={'rotate(-2deg)'} zIndex={0}/>
                <ServeMedia onMouseOver={(event)=>{over(event)}} onMouseLeave={()=>{leave()}} MediaId='M2' MediaFlag={'img'} MediaSrc={logowallImg} MediaRight={'8vw'} MediaTop={'-1vh'} MediaTransform={'rotate(5deg)'} zIndex={0}/>
                <ServeMedia onMouseOver={(event)=>{over(event)}} onMouseLeave={()=>{leave()}} MediaId='M3' MediaFlag={'img'} MediaSrc={talkingImg} MediaRight={'-2vw'} MediaTop={'26vh'} MediaTransform={'rotate(0deg)'} zIndex={3}/>
                <ServeMedia onMouseOver={(event)=>{over(event)}} onMouseLeave={()=>{leave()}} MediaId='M4' MediaFlag={'img'} MediaSrc={photoroomImg} MediaLeft={'22vw'} MediaTop={'40vh'} MediaTransform={'rotate(-8deg)'} zIndex={2}/>
                <ServeMedia onMouseOver={(event)=>{over(event)}} onMouseLeave={()=>{leave()}} MediaId='M5' MediaFlag={'img'} MediaSrc={officeImg} MediaLeft={'-10vw'} MediaTop={'55vh'} MediaTransform={'rotate(2deg)'} zIndex={4}/>
                <ServeMedia onMouseOver={(event)=>{over(event)}} onMouseLeave={()=>{leave()}} MediaId='M6' MediaFlag={'img'} MediaSrc={meetingImg} MediaRight={'10vw'} MediaTop={'62vh'} MediaTransform={'rotate(-3deg)'} zIndex={0}/>

        </Box>
    );
};
    
export default Serve;


function ServeMedia(MediaFlag){
    if(MediaFlag.MediaFlag==='video'){
        return(
            <Box
                onMouseOver={MediaFlag.onMouseOver}
                onMouseLeave={MediaFlag.onMouseLeave}

                className='serveMedia'
                sx={{
                    width:{
                        xs:'10em',
                        sm:'16em',
                    },
                    height:{
                        xs:'auto',
                        sm:'auto',
                    },
                    display:'flex',
                    position:'fixed',
                    alignItems:'center',
                    right:MediaFlag.MediaRight,
                    top:MediaFlag.MediaTop,
                    left:MediaFlag.MediaLeft,
                    transform: MediaFlag.MediaTransform,
                    zIndex:MediaFlag.zIndex,
                    
                }}
            >
                <Avatar
                    sx={{
                        width:'100%',
                        height:'100%',
                        borderRadius:'0',
                        objectFit: 'cover',
                    }}
                    alt=""
                    src={Photo}
                >
                </Avatar>
                <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    style={{
                        position:'absolute',
                        width:'100%',
                        padding:'0 0.4em',
                        boxSizing:'border-box',
                        height:{
                            xs:'6em',
                            sm:'10em',
                        },
                        borderRadius:'0',
                    
                    }}
                >
                    <source src={MediaFlag.MediaSrc} type="video/mp4" />
                </video>
                <Box
                className='serveMediaMask'
                sx={{
                    width:'100%',
                    height:'100%',
                    backgroundColor:'#000000',
                    position:'absolute',
                    opacity:0,
                }}
                >
                </Box>
            </Box>


        )
    }else if(MediaFlag.MediaFlag==='img'){
        return(
            <Box 
            onMouseEnter={MediaFlag.onMouseOver}
            onMouseLeave={MediaFlag.onMouseLeave}

                className='serveMedia'
                sx={{
                    width:{
                        xs:'10em',
                        sm:'16em',
                    },
                    height:{
                        xs:'auto',
                        sm:'auto',
                    },
                    display:'flex',
                    position:'fixed',
                    alignItems:'center',
                    right:MediaFlag.MediaRight,
                    top:MediaFlag.MediaTop,
                    left:MediaFlag.MediaLeft,
                    transform: MediaFlag.MediaTransform,
                    zIndex:MediaFlag.zIndex,
                    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
                    
                }}
            >

                <Avatar
                    sx={{
                        width:'100%',
                        height:'100%',
                        borderRadius:'0',
                        objectFit: 'cover',
                    }}
                    alt=""
                    src={Photo}
                >
                </Avatar>
                <Avatar

                    sx={{
                        position:'absolute',
                        width:'100%',
                        padding:'0 0.4em',
                        boxSizing:'border-box',
                        height:{
                            xs:'6em',
                            sm:'10em',
                        },
                        borderRadius:'0',
                    }}
                alt=""
                src={MediaFlag.MediaSrc}
                />
                <Box
                className='serveMediaMask'
                sx={{
                    width:'100%',
                    height:'100%',
                    backgroundColor:'#000000',
                    position:'absolute',
                    opacity:0,
                }}
                >
                </Box>
            </Box>



        )
    }
    
}