import Box from '@mui/material/Box';
import {useRef,useEffect} from 'react';
import { Typography,Link } from '@mui/material';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import {useMyContext } from './Context';
import WorkVideo from './homeComponents/img/digital_products.mp4';

import { Draggable } from "gsap/Draggable";
import { Observer } from 'gsap/Observer';
import isscroll from "./isscroll";
import Avatar from '@mui/material/Avatar';
import { NavLink,useNavigate,useParams } from "react-router-dom";

import bailuImg from './caseImgs/headImgs/bl.jpg';
import bqImg from './caseImgs/headImgs/bq.jpg';
import phaetusImg from './caseImgs/headImgs/phaetus.jpg';
import phaetus10thImg from './caseImgs/headImgs/phaetus10th.jpg';
import zcmImg from './caseImgs/headImgs/zcm.jpg';
import reponseImg from './img/LR.jpg';


gsap.registerPlugin(Draggable);
gsap.registerPlugin(Observer);


const Work = () => {
    let {id} = useParams();

    const context = useMyContext();
    const caseContainer = useRef(null);

    useGSAP(()=>{
        if(context.state.MoveCompleted){

            gsap.to('.WorkWrapper',{duration:2,clipPath: 'inset(0% 0% 0% 0%)',backgroundColor:'white',ease: "power4.inOut"});
        }
    },{dependencies: [context.state.MoveCompleted]})


    useEffect(() => {
        context.set.setNavShow(true);

        let cases = gsap.utils.toArray(".Work");

        let wrap = gsap.utils.wrap(0, cases.length);

        let currentIndex = context.fovmd ? -1 : 0;

        isscroll(caseContainer.current,false);

        let images = document.querySelectorAll(".bg"),
            outerWrappers = gsap.utils.toArray(".outer"),
            innerWrappers = gsap.utils.toArray(".inner"),
            animating;
        if(context.fovmd){
            gsap.set(outerWrappers, { yPercent: 100 });
            gsap.set(innerWrappers, { yPercent: -100 });

            gotoSection(parseInt(id)-1,1);

            Observer.create({
                type: "wheel,touch,pointer",
                wheelSpeed: -1,
                onDown: () => !animating && gotoSection(currentIndex - 1, -1),
                onUp: () => !animating && gotoSection(currentIndex + 1, 1),
                tolerance: 10,
                preventDefault: true
            });
        }else{

            let zIndexs = [];
            cases.forEach(e=>{
                zIndexs.push(parseInt(getComputedStyle(e).zIndex));
            })
            let maxIndex = Math.max(...zIndexs);

            cases[parseInt(id)-1].style.zIndex = maxIndex + 1;

            currentIndex = parseInt(id)-1;

            let draggable = Draggable.create(cases, {
                type: "x",
                bounds: {top: 0, left: -window.innerWidth, width: window.innerWidth*3, height: window.innerHeight},
                inertia: true,
                onDragEnd: function () {
                    const halfWindow = Math.floor(window.innerWidth/8);

                    const dragDistance = this.endX - this.startX;

                    gsap.to(this.target.children[0].children[1],{
                        duration:0.5,
                        background: 'rgba(0, 0, 0, 0.2)',
                        webkitBackdropFilter: ' blur(0px)',
                        backdropFilter: 'blur(0px)'
                    })

                    if(this.getDirection('start')==='left'){

                        if(dragDistance >= -halfWindow){
                            //1. 让其回到原位
                            gsap.to(this.target, {
                                duration: 0.5,
                                x: 0,
                                ease: "power1.out",
                                onStart: disableDraggable,
                                onComplete: () => {
                                    cases[wrap(currentIndex+1)].style.zIndex = wrap(currentIndex+1);
                                    enableDraggable();

                                    gsap.to(cases[wrap(currentIndex+1)].children[0].children[1],{
                                        duration:0.3,
                                        background: 'rgba(0, 0, 0, 0.2)',
                                        webkitBackdropFilter: ' blur(0px)',
                                        backdropFilter: 'blur(0px)',
                                    })
                                }
                            })

                            gsap.to(this.target.children[0].children[1],{
                                duration:0.5,
                                background: 'rgba(0, 0, 0, 0.2)',
                                webkitBackdropFilter: 'blur(0px)',
                                backdropFilter: 'blur(0px)',
                            })
                            gsap.to(this.target.children[0].children[0].children[0],{
                                duration:0.5,
                                width:'100%',
                                height:'100%'
                            });

                            //2. 让后面的图片回到最后一排

                        }else{
                            gotoBox(currentIndex+1);
                            //1.让其从窗口左侧滑出
                            gsap.to(this.target, {
                                duration: 0.5,
                                x: -window.innerWidth,
                                ease: "power1.out",
                                onStart: ()=>{
                                    disableDraggable();
                                    gsap.to(cases[wrap(currentIndex)].children[0].children[1],{
                                        duration:0.5,
                                        background: 'rgba(0, 0, 0, 0.2)',
                                        webkitBackdropFilter: 'blur(0px)',
                                        backdropFilter: 'blur(0px)',
                                    });
                                },
                                onComplete: () => {
                                    // gsap.set(this.target,{zIndex:0,x:0})
                                    gsap.set(this.target.children[0].children[0].children[0],{
                                        width:'100%',
                                        height:'100%'
                                    });
                                    gsap.set(this.target,{zIndex:wrap(currentIndex-1),x:0});
                                    enableDraggable();
                                }
                            })


                        }


                    }else if(this.getDirection('start')==='right'){

                        if(dragDistance>=halfWindow){
                            gotoBox(currentIndex-1);
                            gsap.to(this.target, {
                                duration: 0.5,
                                x: window.innerWidth,
                                ease: "power1.out",
                                onStart: ()=>{
                                    disableDraggable();
                                    gsap.to(cases[wrap(currentIndex)].children[0].children[1],{
                                        duration:0.5,
                                        background: 'rgba(0, 0, 0, 0.2)',
                                        webkitBackdropFilter: 'blur(0px)',
                                        backdropFilter: 'blur(0px)',
                                    })
                                },
                                onComplete: () => {
                                    gsap.set(this.target,{zIndex:wrap(currentIndex+1),x:0});
                                    gsap.set(this.target.children[0].children[0].children[0],{
                                        width:'100%',
                                        height:'100%'
                                    });
                                    enableDraggable();
                                }
                            })

                        }else{
                            gsap.to(this.target, {
                                duration: 0.5,
                                x: 0,
                                ease: "power1.out",
                                onStart: disableDraggable,
                                onComplete: () => {

                                    cases[wrap(currentIndex-1)].style.zIndex = wrap(currentIndex-1);
                                    enableDraggable();

                                }
                            })

                            gsap.to(this.target.children[0].children[0].children[0],{
                                duration:0.5,
                                width:'100%',
                                height:'100%'
                            });


                        }

                    }
                    if(context.fovsm){
                        gsap.to(".bar_width", { duration: 0.5, width: `${Math.floor(100 / cases.length) * (currentIndex + 1)}%` });//案例绑定滚动条进度
                    }else if(context.fovmd){
                        gsap.to(".bar_width", { duration: 0.5, height: `${Math.floor(100 / cases.length) * (currentIndex + 1)}%` });//案例绑定滚动条进度
                    }

                },
                onDragStart: function() {

                    if(this.getDirection('start')==='left'){

                        cases[wrap(currentIndex+1)].style.zIndex = this.target.style.zIndex*1 - 10;
                    }else if(this.getDirection('start')==='right'){

                        cases[wrap(currentIndex-1)].style.zIndex = this.target.style.zIndex*1 - 10;
                    }

                },
                onDrag:function(){
                    const dragDistance = this.endX - this.startX;

                    const range = 70;
                    let percentD = Math.floor((Math.abs(dragDistance)/window.innerWidth)*100);
                    if(percentD>range){percentD=range;}
                    let percentDistance = `${100-percentD > 80 ? 100-percentD : 80}%`;
                    let percentDistance2 = `${100+percentD < 120 ? 100+percentD : 120 }%`;
                    this.target.children[0].children[0].children[0].style.width = percentDistance;
                    this.target.children[0].children[0].children[0].style.height = percentDistance;


                    gsap.set(this.target.children[0].children[1],{
                        background: `rgba(0, 0, 0, ${0.2+0.6*(percentD/range)})`,
                        webkitBackdropFilter: `blur(${Math.floor(10*(percentD/range))}px)`,
                        backdropFilter: `blur(${Math.floor(10*(percentD/range))}px)`
                    })

                    if(this.getDirection('start')==='left'){
                        cases[wrap(currentIndex+1)].children[0].children[0].children[0].style.width = percentDistance2;
                        cases[wrap(currentIndex+1)].children[0].children[0].children[0].style.height = percentDistance2;

                        gsap.set(cases[wrap(currentIndex+1)].children[0].children[1],{
                            background: `rgba(0, 0, 0, ${0.8-0.6*(percentD/range)})`,
                            webkitBackdropFilter: `blur(${Math.ceil(10-10*(percentD/range))}px)`,
                            backdropFilter: `blur(${Math.ceil(10-10*(percentD/range))}px)`
                        })
                    }else if(this.getDirection('start')==='right'){
                        cases[wrap(currentIndex-1)].children[0].children[0].children[0].style.width = percentDistance2;
                        cases[wrap(currentIndex-1)].children[0].children[0].children[0].style.height = percentDistance2;

                        gsap.set(cases[wrap(currentIndex-1)].children[0].children[1],{
                            background: `rgba(0, 0, 0, ${0.8-0.6*(percentD/range)})`,
                            webkitBackdropFilter: `blur(${Math.ceil(10-10*(percentD/range))}px)`,
                            backdropFilter: `blur(${Math.ceil(10-10*(percentD/range))}px)`
                        })
                    }

                }
            });

            function gotoBox(index){
                index = wrap(index);
                currentIndex = index;
            }

            function disableDraggable() {
                draggable.forEach(instance => instance.disable());
            }

            function enableDraggable() {
                draggable.forEach(instance => instance.enable());
            }
        }



        function gotoSection(index, direction) {
            index = wrap(index); // make sure it's valid
            animating = true;
            let fromTop = direction === -1,
                dFactor = fromTop ? -1 : 1,
                tl = gsap.timeline({
                    defaults: { duration: 1.25, ease: "power1.inOut" },
                    onComplete: () => animating = false
                });
            if (currentIndex >= 0) {
                // The first time this function runs, current is -1
                gsap.set(cases[currentIndex], { zIndex: 0 });
                tl.to(images[currentIndex], { yPercent: -15 * dFactor })
                    .set(cases[currentIndex], { autoAlpha: 0 });
            }
            gsap.set(cases[index], { autoAlpha: 1, zIndex: 1 });
            tl.fromTo([outerWrappers[index], innerWrappers[index]], {
                yPercent: i => i ? -100 * dFactor : 100 * dFactor
            }, {
                yPercent: 0
            }, 0)
            tl.fromTo(images[index], { yPercent: 15 * dFactor }, { yPercent: 0 }, 0);

            currentIndex = index;
            gsap.to(".bar_width", { duration: 0.5, height: `${Math.floor(100 / cases.length) * (currentIndex + 1)}%` });//案例绑定滚动条进度
        }

    }, []);




    return(
        <Box
        className='WorkWrapper'
        sx={{
          width: '100vw', 
          height:'100%',
          position:'fixed',
          zIndex:'9999',
          overflowX:'auto',
          whiteSpace: 'nowrap',
          clipPath: 'inset(49.9% 0% 50.1% 0%)',
        }}
        >
            <Box
                ref={caseContainer}
                className='WorkContainer'
                sx={{
                    width:'100vw',
                    height:'100%',
                    // overflowX:'auto',
                    // display: 'flex',
                    // whiteSpace: 'nowrap',
                    position:'relative',
                }}>
                <Case zIndex={6} Num={1} MediaFlag={'img'} CaseTitle={'BC设计 | 白鹭资管品牌官网重塑'} headImg={bailuImg} />
                <Case zIndex={5} Num={2} MediaFlag={'img'} CaseTitle={'必趣科技品牌全案设计——BIQU'} headImg={bqImg} />
                <Case zIndex={4} Num={3} MediaFlag={'img'} CaseTitle={'中触媒新材料品牌国际官网设计'} headImg={zcmImg} />
                <Case zIndex={3} Num={4} MediaFlag={'img'} CaseTitle={'瑞士护肤品牌La Réponse | 品牌全案及艺术展设计'} headImg={reponseImg} />
                <Case zIndex={2} Num={5} MediaFlag={'img'} CaseTitle={'3D打印品牌数字设计——Phaetus'} headImg={phaetusImg} />
                <Case zIndex={1} Num={6} MediaFlag={'img'} CaseTitle={'Phaetus10周年活动-品牌互动营销页'} headImg={phaetus10thImg} />
            </Box>
            <ProgressBar/>
        </Box>
       
    )
};
    
export default Work;

function Case({zIndex,Num,MediaFlag,CaseTitle,headImg}){
    const context = useMyContext();
    const navigate = useNavigate();
    //需要在这段动画完成之后再跳进案例内页
    const CaseButtonClick =(Num)=>{
        const tl =gsap.timeline({
            onComplete:()=>{
                navigate(`/detail/${Num}`);
            }
        })
        tl.to('.CaseButton',{duration:1,opacity:0,})
        .to('.CaseTitle',{duration:1,opacity:0,},"<")
    }

    if(context.fovmd){

        return (

            <Box
                className='Work'
                sx={{
                    width:'100vw',
                    height:'100%',
                    zIndex:0,
                    position: 'absolute',
                    cursor:'pointer'
                }}

                onClick={()=>{context.set.setNavShow(false);CaseButtonClick(Num);}}

            >
                <Box className={'outer'}
                    sx={{
                        width:'100%',
                        height:'100%',
                        overflowY:'hidden'
                    }}
                >
                    <Box className={'inner'}
                        sx={{
                            width:'100%',
                            height:'100%',
                            overflowY:'hidden'
                        }}
                    >
                        <Box className={'bg'}
                             sx={{
                                 height:'100vh'
                             }}
                        >

                            <CaseMedia MediaFlag={ MediaFlag } headImg={headImg}/>

                            <Box
                                sx={{
                                    boxSizing:'border-box',
                                    textAlign:'center',
                                    display:'flex',
                                    flexDirection:'column',
                                    justifyContent:'flex-end',
                                    alignItems:'flex-start',
                                    width:'100vw',
                                    height:'100vh',
                                    backgroundColor:'rgba(0, 0, 0, 0.4)',
                                    // position: 'absolute',
                                }}
                            >
                                <Typography
                                    className="CaseTitle"
                                    color="text.primary"
                                    variant="h5"
                                    sx={{
                                        padding:'2%',
                                        whiteSpace:'pre-wrap',
                                        boxSizing:'border-box',
                                        textAlign:'center',
                                        zIndex:2,
                                        opacity:1,
                                    }}
                                >
                                    {CaseTitle}
                                </Typography>

                                {/*<Box*/}
                                {/*    className="CaseButton"*/}
                                {/*    sx={{*/}
                                {/*        marginTop:'2em',*/}
                                {/*        padding:'0.8em 3em',*/}
                                {/*        borderRadius:'100px',*/}
                                {/*        background: 'rgba(251, 251, 253, 0.2)',*/}
                                {/*        webkitBackdropFilter: ' blur(10px)',*/}
                                {/*        backdropFilter: 'blur(10px)',*/}
                                {/*        opacity:1,*/}
                                {/*        cursor:'pointer'*/}
                                {/*    }}*/}
                                {/*    onClick={()=>{context.set.setNavShow(false);CaseButtonClick(Num)}}*/}
                                {/*>*/}
                                {/*    <Typography*/}
                                {/*        color="text.primary"*/}
                                {/*        variant="button"*/}
                                {/*    >*/}
                                {/*        <span>浏览</span>*/}
                                {/*    </Typography>*/}
                                {/*</Box>*/}

                            </Box>
                        </Box>
                    </Box>
                </Box>

            </Box>
        );
    }else{
        return (

            <Box
                className='Work'
                sx={{
                    width:'100vw',
                    height:'100%',
                    zIndex:zIndex,
                    position: 'absolute',
                }}

                onClick={()=>{context.set.setNavShow(false);CaseButtonClick(Num);}}
            >

                            <CaseMedia MediaFlag={ MediaFlag } headImg={headImg}/>

                            <Box
                                sx={{
                                    boxSizing:'border-box',
                                    textAlign:'center',
                                    display:'flex',
                                    flexDirection:'column',
                                    justifyContent:'flex-end',
                                    alignItems:'flex-start',
                                    width:'100%',
                                    height:'100%',
                                    backgroundColor:'rgba(0, 0, 0, 0.4)',
                                    // position: 'absolute',
                                }}
                            >
                                <Typography
                                    className="CaseTitle"
                                    color="text.primary"
                                    variant="h5"
                                    sx={{
                                        padding:'2%',
                                        whiteSpace:'pre-wrap',
                                        boxSizing:'border-box',
                                        textAlign:'center',
                                        zIndex:2,
                                        opacity:1,
                                    }}
                                >
                                    {CaseTitle}
                                </Typography>

                                {/*<Box*/}
                                {/*    className="CaseButton"*/}
                                {/*    sx={{*/}
                                {/*        marginTop:'2em',*/}
                                {/*        padding:'0.8em 3em',*/}
                                {/*        borderRadius:'100px',*/}
                                {/*        background: 'rgba(251, 251, 253, 0.2)',*/}
                                {/*        webkitBackdropFilter: ' blur(10px)',*/}
                                {/*        backdropFilter: 'blur(10px)',*/}
                                {/*        opacity:1,*/}
                                {/*        cursor:'pointer'*/}
                                {/*    }}*/}
                                {/*    onClick={()=>{context.set.setNavShow(false);CaseButtonClick(Num)}}*/}
                                {/*>*/}
                                {/*    <Typography*/}
                                {/*        color="text.primary"*/}
                                {/*        variant="button"*/}
                                {/*    >*/}
                                {/*        <span>浏览</span>*/}
                                {/*    </Typography>*/}
                                {/*</Box>*/}

                            </Box>

            </Box>
        );
    }


}

function CaseMedia(MediaFlag){
    // console.log(MediaFlag.MediaFlag);
    if(MediaFlag.MediaFlag==='video'){
        return(
            <Box>
                <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    style={{
                        position: 'absolute', 
                        width: '100%', 
                        height: '100%',
                        objectFit: 'cover',
                    
                    }}
                >
                    <source src={WorkVideo} type="video/mp4" />
                </video>
                <Box
                className='mask'
                  sx={{
                    position:'absolute',
                    width:'100%',
                    height:'100%',
                    background: 'rgba(0, 0, 0, 0.2)',
                    webkitBackdropFilter: ' blur(0px)',
                    backdropFilter: 'blur(0px)',
                  }}
                >
                </Box>
            </Box>

        )
    }else if(MediaFlag.MediaFlag==='img'){
        return(
            <Box>
            <Avatar
                sx={{
                    position: 'absolute', 
                    width: '100%', 
                    height: '100%',
                    borderRadius:'0',
                    objectFit: 'cover',
                }}
                alt=""
                src={MediaFlag.headImg}
            />
            <Box
            className='mask'
              sx={{
                position:'absolute',
                width:'100%',
                height:'100%',
                background: 'rgba(0, 0, 0, 0.2)',
                webkitBackdropFilter: ' blur(0px)',
                backdropFilter: 'blur(0px)',
              }}
            >
            </Box>
 
        </Box>

        )
    }
    
}

function ProgressBar(){
    return(
        <Box
            sx={{
                right:{
                    sm:'1em'
                },
                bottom:{
                    xs:'0',
                    sm:'1em',
                },
                height:{
                    sm: '20vh',
                },
                position: 'absolute', 
                display:'flex',

                padding:{
                    xs:'0',
                    sm:'0.2em',
                },
                boxSizing:'border-box',
                width:{
                    xs:'100%',
                    sm:'0.8em',
                },
                borderRadius:{
                    xs:'0',
                    sm:'1em',
                },
                background: 'rgba(0, 0, 0, 0.2)',
                webkitBackdropFilter: ' blur(10px)',
                backdropFilter: 'blur(10px)',
                zIndex:'2000',
            }}
        >
            <Box className='bar_width'
                sx={{
                    background: {
                        xs:'linear-gradient(-90deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%)',
                        sm:'linear-gradient(0deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%)',
                    },
                    borderRadius:{
                        xs:'0',
                        sm:'1em',
                    },
                    width:{
                        xs:'20%',
                        sm:'100%',
                    },
                    height:{
                        xs:'0.3em',
                        sm:'20%',
                    },
                }}
            >
            </Box>
        </Box>
    )
}