
import React from 'react';
import {useRef, } from 'react'
import Box from '@mui/material/Box'
import "./BClogo.css"
import { useGSAP } from '@gsap/react';
import gsap from 'gsap'
import {useMyContext } from './Context';

const BClogo = ({color,animationType})=>{
    const context = useMyContext();
    const container = useRef();

    useGSAP(()=>{
        if(context.state.isLoading && animationType==="type1")
        {const navlogo_tl=gsap.timeline({progress: context.state.progress});
        navlogo_tl.to(".B path",{duration:1,
        clipPath: "inset(0% 0% 0% 0%)",
        ease: "expo.inOut",
        stagger:.05,})
        navlogo_tl.to(".C path",{duration: 1,
        clipPath: "inset(0% 0% 0% 0%)",
        ease: "expo.inOut",
        stagger:.05,},"<0.35")
        }
    },{ scope: container })

    useGSAP(()=>{
        if(!context.state.isLoading && animationType==="type2")
        {
            gsap.to(".B",{
              duration:1.3,
              x:364.81/2+0.69,
              ease: "power3.inOut",
            })
            gsap.to(".C",{
                duration:1.3,
                x:-361.29/2-4.31,
                ease: "power3.inOut",
              },"<")
    }
    },{dependencies: [context.state.isLoading], scope: container })

    useGSAP(()=>{
        if(context.state.MoveCompleted && animationType==="type3")
        {
            gsap.to(".C",{
                x:-361.29,
              })
              gsap.to(".B path",{
                clipPath: "inset(0% 0% 0% 0%)",
              })
              gsap.to(".C path",{
                  clipPath: "inset(0% 0% 0% 0%)",
                })
    }
    },{dependencies: [context.state.MoveCompleted],scope: container })


    return(

        <svg ref={container}  height='100%' className="NavLogo" viewBox="0 0 731 365">
                <g className="B">
                <path fill={color}  d="M0 0v9.11h313.49A90.158 90.158 0 0 0 279 0H0Z" className="B1"/>
                <path fill={color}  d="M0 38.47v9.11h353.63a94.048 94.048 0 0 0-5.7-9.11H0Z" className="B2"/>
                <path fill={color}  d="M364.65 86.06c-.18-3.08-.513-6.12-1-9.12H0v9.12h364.65Z" className="B3"/>
                <path fill={color}  d="M246.82 124.53h111.65c.06-.14.13-.28.18-.43a89.172 89.172 0 0 0 2.89-8.68H0v9.11h246.82Z" className="B4"/>
                <path fill={color}  d="M2.21 153.89c-.467 3.013-.87 6.05-1.21 9.11h328.93a93.481 93.481 0 0 0 10-9.11H2.21Z" className="B5"/>
                <path fill={color}  d="M114.74 201.47h214.9a91.449 91.449 0 0 0-13.91-9.11H0c.173 3.06.417 6.097.73 9.11h114.01Z" className="B6"/>
                <path fill={color}  d="M354.33 230.83c.8 1.51 1.54 3 2.25 4.59.38.83.82 1.83 1.14 2.55.32.72.78 1.9.78 1.9L0 239.94v-9.11h354.33Z" className="B7"/>
                <path fill={color}  d="M364.72 269.3H0v9.12h364.65a84.688 84.688 0 0 0 .07-9.12Z" className="B8"/>
                <path fill={color}  d="M0 307.78v9.11h353.63a92.889 92.889 0 0 0 4.36-9.11H0Z" className="B9"/>
                <path fill={color}  d="M0 346.25v9.11h313.49a91.123 91.123 0 0 0 14.81-9.11H0Z" className="B10"/>
                </g>
                <g className="C">
                <path fill={color}  d="M494.22 9.11a180.871 180.871 0 0 0-22.5 9.11h159a180.996 180.996 0 0 0-22.5-9.11h-114Z" className="C1"/>
                <path fill={color}  d="M674 47.58H428.46a206.84 206.84 0 0 0-9.32 9.12h264.19c-2.99-3.15-6.11-6.19-9.33-9.12Z" className="C2"/>
                <path fill={color}  d="M396.43 86.06a179.623 179.623 0 0 0-5.32 9.11h320.25c-1.68-3.1-3.46-6.13-5.31-9.11H396.43Z" className="C3"/>
                <path fill={color}  d="M375.56 133.64h103.72a87.175 87.175 0 0 1 7.13-9.11H378.33c-.99 3-1.92 6.04-2.77 9.11Zm240.26-9.11a88.595 88.595 0 0 1 7.12 9.11h100.14c.68-1.29 3.45-7.1 4.21-8.7l.18-.41H615.82Z" className="C4"/>
                <path fill={color}  d="M370 163c.32-3.06.717-6.093 1.19-9.1a182.018 182.018 0 0 0-1.91 18.21H465a85.437 85.437 0 0 1 1.59-9.11H370Z" className="C5"/>
                <path fill={color}  d="M483.74 201.47H370c.32 3.06.717 6.097 1.19 9.11h98a83.436 83.436 0 0 1-2.59-9.11h17.14Z" className="C6"/>
                <path fill={color}  d="m727.5 239.87-358.5.07h9.33a197.5 197.5 0 0 0 3.31 9.12h114.17a87.912 87.912 0 0 1-9.4-9.12h129.41a86.323 86.323 0 0 1-9.41 9.12h123.88c-.77-2.8-1.75-6.35-2.79-9.19Z" className="C7"/>
                <path fill={color}  d="M396.43 278.42c1.926 3.1 3.926 6.137 6 9.11H700a167.385 167.385 0 0 0 6.05-9.11H396.43Z" className="C8"/>
                <path fill={color}  d="M428.46 316.89a192.47 192.47 0 0 0 10.78 9.11h224c3.707-2.9 7.3-5.937 10.78-9.11H428.46Z" className="C9"/>
                <path fill={color}  d="M551.24 364.47a181.865 181.865 0 0 0 57-9.11h-114a181.894 181.894 0 0 0 57 9.11Z" className="C10"/>
                </g>
            
        </svg>

    )
}

export default BClogo;