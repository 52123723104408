import Box from '@mui/material/Box'
import Team1 from '../img/team1.jpg'
import Team2 from '../img/team2.jpg'
import Team3 from '../img/team3.jpg'
import Team4 from '../img/team4.jpg'
import TeamB from '../img/teamB.svg'
import TeamC from '../img/teamC.svg'
import IconStraw from '../img/iconStraw.svg'
import { Typography,Link } from '@mui/material'
import { useEffect } from "react";
import { useGSAP } from '@gsap/react';
import gsap from 'gsap'
import {useMyContext } from '../Context';
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Unstable_Grid2'
import { Draggable } from 'gsap/Draggable';

const Team = (zIndex) => {
    const context = useMyContext();

    useEffect(()=>{
        Draggable.create(".ballSlider", {
            type: "x",
            bounds: ".sliderBounds"
        });
    },[]);
    return (

        <Box
        className='HomeSection'
            sx={{
                backgroundColor:'#ffffff',
                zIndex:zIndex,
                width:'100%',
                height:'100vh',
                position:'absolute',
                opacity:1,
            }}
        >

            <Grid
                sx={{
                    position:'absolute',
                }}
                container
            >
                <Grid
                    xs={12} sm={4}
                >
                    <Avatar
                        className='teamImg'
                        sx={{
                            clipPath: 'inset(49.9% 49.9% 50.1% 50.1% round 50px)',
                            width:{
                                xs:'100%',
                                sm:'100%',
                            },
                            height:{
                                xs:'30vh',
                                sm:'100vh',
                            },
                            borderRadius:'0',
                            objectFit: 'cover',
                        }}
                        alt=""
                        src={Team1}
                    />
                </Grid>

                <Grid
                    xs={12} sm={4}
                    direction={{
                        xs: 'row',
                        sm: 'column',
                    }}
                    container
                >
                    <Avatar
                        className='teamImg'
                        sx={{
                            clipPath: 'inset(49.9% 49.9% 50.1% 50.1% round 50px)',
                            width:{
                                xs:'50%',
                                sm:'100%',
                            },
                            height:{
                                xs:'30vh',
                                sm:'50vh',
                            },
                            borderRadius:'0',
                            objectFit: 'cover',
                        }}
                        alt=""
                        src={Team2}
                    />

                    <Avatar
                        className='teamImg'
                        sx={{
                            clipPath: 'inset(49.9% 49.9% 50.1% 50.1% round 50px)',
                            boxSizing:'border-box',
                            width:{
                                xs:'50%',
                                sm:'100%',
                            },
                            height:{
                                xs:'21vh',
                                sm:'50vh',
                            },
                            borderRadius:'0',
                            objectFit: 'cover',
                        }}
                        alt=""
                        src={Team3}
                    />
                </Grid>

                <Grid
                    xs={12} sm={4}
                    container
                >
                    <Avatar
                        className='teamImg' 
                        sx={{
                            clipPath: 'inset(49.9% 49.9% 50.1% 50.1% round 50px)',
                            width:{
                                xs:'100%',
                                sm:'100%',
                            },
                            height:{
                                xs:'40vh',
                                sm:'100vh',
                            },
                            borderRadius:'0',
                            objectFit: 'cover',
                        }}
                        alt=""
                        src={Team4}
                    />
                </Grid>

            </Grid>

            <Box
                sx={{
                    width:'100%',
                    height:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                }}
            >
                <Avatar
                    className='teamSvgB'
                    sx={{
                        opacity:0,
                        position:'absolute',
                        mixBlendMode: 'multiply',
                        boxSizing:'border-box',
                        left:{
                            xs:'auto',
                            sm:'10vw',
                        },
                        top:{
                            xs:'9vh',
                            sm:'auto',
                        },
                        width:{
                            xs:'100%',
                            sm:'35vw',
                        },
                        height:{
                            xs:'35vh',
                            sm:'auto',
                        },
                        borderRadius:'0',
                        objectFit: 'cover',
                    }}
                    alt=""
                    src={TeamB}
                />

                <Avatar
                className='teamSvgC'
                    sx={{
                        opacity:0,
                        position:'absolute',
                        mixBlendMode: 'multiply',
                        boxSizing:'border-box',
                        right:{
                            xs:'auto',
                            sm:'10vw',
                        },
                        bottom:{
                            xs:'14vh',
                            sm:'auto',
                        },
                        width:{
                            xs:'100%',
                            sm:'35vw',
                        },
                        height:{
                            xs:'35vh',
                            sm:'auto',
                        },
                        borderRadius:'0',
                        objectFit: 'cover',
                    }}
                    alt=""
                    src={TeamC}
                />

                <Avatar
                className='iconStraw'
                    sx={{
                        opacity:0,
                        borderRadius:'0',
                        boxSizing:'border-box',
                        padding:'0.8em',
                        right:{
                            xs:'0',
                            sm:'66.66666vw',
                        },
                        bottom:{
                            xs:'40vh',
                            sm:'42vh',
                        },
                        position:'absolute',
                        background:'rgba(255, 255, 255, 0.2)',
                        boxShadow:'0px 15px 50px rgba(0, 0, 0, 0.0), inset 0px 1px 1px rgba(255, 255, 255, 0.2)',
                        backdropFilter:'blur(5px)',
                        width:{
                            xs:'9vh',
                            sm:'8vh',
                        },
                        height:{
                            xs:'9vh',
                            sm:'8vh',
                        },
                    }}
                    alt=""
                    src={IconStraw}
                />

                <Grid
                    container
                    xs={12} sm={4}
                    sx={{
                        position:'absolute',
                        left:{
                            xs:'0',
                            sm:'auto',
                        },
                        top:{
                            xs:'30vh',
                            sm:'42vh',
                        },
                    }}
                >
                    <Box
                        className='rainbowSlider'
                        sx={{
                            opacity:0,
                            borderRadius:'0',
                            boxSizing:'border-box',
                            padding:{
                                xs:'2.5vh',
                                sm:'3.2vh',
                            },
                            background:'rgba(255, 255, 255, 0.2)',
                            boxShadow:'0px 15px 50px rgba(0, 0, 0, 0.0), inset 0px 1px 1px rgba(255, 255, 255, 0.2)',
                            backdropFilter:{
                                xs:'blur(5px)',
                                sm:'blur(30px)',
                            },
                            width:{
                                xs:'50%',
                                sm:'100%',
                            },
                            height:{
                                xs:'9vh',
                                sm:'8vh',
                            },
                        }}
                    >
                        <Box
                            className='sliderBackground'
                            sx={{
                                position:'relative', 
                                display:'flex',
                                alignItems:'center',
                                justifyContent: 'center',
                                boxSizing:'border-box',
                                width:{
                                    xs:'0%',
                                    sm:'0%',
                                },
                                height:{
                                    xs:'100%',
                                    sm:'100%',
                                },
                                background:'linear-gradient(90deg, #FF0000 0%, #FAFF00 25%, #1AECFA 50%, #4200FF 75.5%, #FF0000 100%)',
                                borderRadius:'10em',
                                opacity:0,
                                
                            }}
                            
                        >
                            <Box
                            className='sliderBounds'
                            sx={{
                                position:'absolute', 
                                display:'flex',
                                alignItems:'center',
                                boxSizing:'border-box',
                                width:{
                                    xs:'94%',
                                    sm:'100%',
                                },
                                height:{
                                    xs:'100%',
                                    sm:'100%',
                                },
                                
                            }}
                            >
                                <Box
                                    sx={{
                    
                                        border:'0.1em solid #ffffff',
                                        boxShadow:{
                                            xs:'0px 4px 6px rgba(0, 0, 0, 0.5)',
                                            sm:'0px 6px 12px rgba(0, 0, 0, 0.3)',
                                        },
                                        boxSizing:'border-box',
                                        position:'absolute',
                                        width:{
                                            xs:'3vh',
                                            sm:'3vh',
                                        },
                                        height:{
                                            xs:'3vh',
                                            sm:'3vh',
                                        },
                                        backgroundColor:'#000000',
                                        borderRadius:'10em'
                                    }}
                                    className='ballSlider'

                                >
                                </Box>
                            </Box>
                            
                        </Box>
                    </Box>
                </Grid>

                <Box
                    className='HomeSectionTwoText'
                    sx={{
                        width:{
                            xs:'90vw',
                            sm:'auto',
                        },
                        position:'fixed',
                        boxSizing:'border-box',
                        bottom:{
                            xs:'5vw',
                            sm:'2%',
                        },
                        padding:'0.8em',
                        borderRadius:'1em',
                        background: 'rgba(0, 0, 0, 0.2)',
                        webkitBackdropFilter: ' blur(10px)',
                        backdropFilter: 'blur(10px)',
                    }}
                >
                    <Typography
                        color="text.primary"
                        variant="p"
                    >
                        BC设计由 UI/UX、品牌策略、动效前端、3D渲染、电商策划运营、动画/插画、创意 等领域专家组成
                    </Typography>
                </Box>

            </Box>
        </Box>
    );
};

export default Team;

