import {useMyContext } from '../Context';
import gsap from 'gsap'
import { useGSAP } from '@gsap/react';
import HomeSection4 from './HomeSection_Four';


export default function ContactUs(){

    const context = useMyContext();
    useGSAP(()=>{
        if(context.state.MoveCompleted){
            const tl=gsap.timeline()
            tl.to('.HomeSection',{duration:2,clipPath: 'inset(0% 0% 0% 0%)',ease: "power4.inOut",})
                .fromTo('.emailButton',{y:10,opacity:0,},{duration:1,y:0,opacity:1,ease: "power2.inOut"},"<1")
                .fromTo('.contactButton',{y:10,opacity:0,},{duration:1,y:0,opacity:1,ease: "power2.inOut"},"<0.2")
                .fromTo('.honor',{y:50,opacity:0,},{duration:1,y:0,opacity:1,ease: "power2.inOut"},"<")
        }
    },{dependencies: [context.state.MoveCompleted]})

    return (
        <HomeSection4 />
    )
};