import Box from '@mui/material/Box'
import { Typography,Link } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import QR from '../img/QR.svg'
import Office from '../img/office.jpg'
import Honor from './img/honor.svg'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import SendIcon from '@mui/icons-material/Send'
import IconButton from '@mui/material/IconButton'
import AlarmIcon from '@mui/icons-material/Alarm'
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {useMyContext } from '../Context'
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import {useRef,useState} from "react";
import axios,{mergeConfig} from "axios";
import "./ContactUs.css";


const Contact = (zIndex) => {

    const context = useMyContext();

    const emailTxt = useRef();
    const sendEmail = useRef();

    const [openError, setOpenError] = useState(false);       //标记邮箱格式错误
    const [openSuccess, setOpenSuccss] = useState(false);    //标记邮件发送成功
    const [openWarning, setOpenWarning] = useState(false);  //标记网络连接导致的发送失败
    const [openFrequent, setOpenFrequent] = useState(false);  //消息提示频率点击发送
    const [lastOkTime, setLastOkTime] = useState();  //标记上一次发送成功的时间戵,首次为undefined

    const [loading, setLoading] = useState(false);

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
    }
    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccss(false);
    }

    const handleWarningClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenWarning(false);
    }
    const handleFrequentClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenFrequent(false);
    }

    useGSAP(()=>{
        gsap.to(".honor",{
            duration:context.fovsm? 15 : 60,
            ease:'none',
            x:context.fovsm ?"-=400vw" : "-=300vw",
            modifiers: {
                
                x: gsap.utils.unitize(x => {
                    const divisor = context.fovsm ? 400 : 300;
                    return parseFloat(x) % divisor ;
                }) //force x value to be between 0 and 500 using modulus
              },
              repeat: -1
        })                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
    },[context.fovsm])

    function isValidEmail(email) {
        // 使用正则表达式验证邮箱格式
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    function shakeInput(){
        sendEmail.current.classList.add('invalid');
        // 动画结束后移除类名，以便下次触发动画
        sendEmail.current.addEventListener('animationend', function() {
            sendEmail.current.classList.remove('invalid');
        });
    }

    function doSendEmail(emailTrim){
        setLoading(true);
        axios.post('https://bcdesign.cn/email/test.php',{
            email:emailTrim
        },{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(function (response) {
                setOpenSuccss(true);

                setLastOkTime(new Date().getTime());

                console.log(response);
            })
            .catch(function (error) {
                setOpenWarning(true);

                console.log(error);
            })
            .finally(function () {
                // always executed
                setLoading(false);
            });
    }

    const sendEmailHandler = email => {
        let emailTrim = email.trim();


        if(isValidEmail(emailTrim)){

            const currentTime = new Date().getTime();
            if(lastOkTime==undefined){
                doSendEmail(emailTrim);
            }else{
                if(currentTime-lastOkTime>30000){
                    doSendEmail(emailTrim);
                }else{
                    shakeInput();
                    setOpenFrequent(true);
                }
            }

        }else{
            // alert('抱歉！您输入的邮箱格式不正确！')
            shakeInput();
            setOpenError(true);
        }
    }

    return (

        <Box
        className='HomeSection'
          sx={{
            zIndex:zIndex,
            width:'100vw',
            height:'100%',
            display:'flex',
            justifyContent:{
                xs:'flex-end',
                sm:'center',
            },
            backgroundImage: `linear-gradient(to bottom, rgba(35,38,41,1), rgba(35,38,41,0)), url(${Office})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position:'fixed',
            alignItems: 'center',
            padding:'0 1em 1em 1em ',
            flexDirection: 'column',
            boxSizing:'border-box',
            clipPath: 'inset(49.9% 0% 50.1% 0%)',
          }}
        >
            <Box
            className='honorContainer'
              sx={{
                position:'fixed',
                left:0,
                top:'10vh',
                width:{
                    xs:'800vw',
                    sm:'600vw',
                },
                display:'flex',
                overflowY:'hidden',
                height:{
                    xs:'5vh',
                    sm:'15vh',
                },

                
              }}
            >
                <Box
                    className='honor'
                    sx={{
                        width:{
                            xs:'400vw',
                            sm:'300vw',
                        },
                        height:'auto',
                        opacity:0,
                    }} 
                >
                    <img src={`${Honor}`} alt="svg" />
                </Box>
                <Box
                    className='honor'
                    sx={{
                        width:{
                            xs:'400vw',
                            sm:'300vw',
                        },
                        height:'auto',
                        opacity:0,
                    }} 
                >
                    <img src={`${Honor}`} alt="svg" />
                </Box>
                
            

               
                {/* <Typography
                    color="text.primary"
                    variant="h2"
                    display="flex"
                    alignItems="center" 
                    whiteSpace="nowrap"
                >
                    
                    Awwwards 2022 数字营销 最佳提名    Awwwards 2022 数字营销 最佳提名
                </Typography> */}
            </Box>
            <ButtonGroup
            className='emailButton'
                variant="contained"
                aria-label="Basic button group"
                sx={{
                    opacity:0,
                    justifyContent: 'center',
                    boxShadow: 'none',
                    width:{
                        xs:'100%',
                        sm:'40%',
                    }
                }}
                ref={sendEmail}
            >
                <TextField
                    inputRef={emailTxt}
                    label={
                        <Typography color="text.primary" variant="button">
                            输入您的企业邮箱，获取BC资料
                        </Typography>
                    }
                    InputProps={{
                        inputMode: 'email'
                    }}
                    sx={{
                        width:'70%',
                        boxSizing:'border-box',

                        '& .MuiOutlinedInput-root': {
                            background: 'rgba(0, 0, 0, 0.2)',
                            webkitBackdropFilter: ' blur(10px)',
                            backdropFilter: 'blur(10px)',
                            border: 'none',
                            borderRadius:'0.4em 0 0 0.4em',
                            transition: 'background-color 0.3s ease', 
                            '&:hover': {
                            background: 'rgba(0, 0, 0, 0.3)', 
                            },
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                    }}
                >
                </TextField>

                {
                    loading ?
                        (<LoadingButton
                            sx={{
                                width:'30%',
                                background: 'rgba(0, 0, 0, 0.3)',
                                webkitBackdropFilter: ' blur(10px)',
                                backdropFilter: 'blur(10px)'
                            }}
                            startIcon={<SendIcon />}
                            loading={loading}
                            loadingPosition="start"
                            variant="contained"
                            disabled
                        >
                            <Typography color="text.primary" variant="button">发送</Typography>
                        </LoadingButton>)
                        :
                        (
                            <Button
                                sx={{
                                    width:'30%',
                                    background: 'rgba(0, 0, 0, 0.3)',
                                    webkitBackdropFilter: ' blur(10px)',
                                    backdropFilter: 'blur(10px)',
                                    '&:hover': {
                                        background: 'rgba(0, 0, 0, 0.5)', // 鼠标移入时的背景颜色
                                    },
                                }}
                                variant="contained"
                                startIcon={<SendIcon sx={{ color: 'white' }} />}
                                onClick={()=>{
                                    sendEmailHandler(emailTxt.current.value);
                                    // console.log(sendEmail.current)
                                }}
                            >
                                <Typography color="text.primary" variant="button">发送</Typography>
                            </Button>
                        )
                }





            </ButtonGroup>

            <Box
            className='contactButton'
                sx={{
                  display:'flex',
                  width:{
                    xs:'100%',
                    sm:'40%',
                  },
                  opacity:0,
                  background: 'rgba(0, 0, 0, 0.3)',
                  webkitBackdropFilter: ' blur(10px)',
                  backdropFilter: 'blur(10px)',
                  borderRadius:'0.5em',
                  marginTop:'1em',
                }}
            >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  width="30%"
                  sx={{
                    padding:'1em',
                    boxSizing:'border-box',
                    borderRight:'1px solid rgba(255, 255, 255, 0.1)',
                  }}
                >
                    <Avatar
                        sx={{
                            width:'100%',
                            height:'auto',
                            borderRadius:'0',
                            objectFit: 'cover',
                        }}
                        alt=""
                        src={QR}
                    ></Avatar>
                    
                    <Typography
                        color="text.primary"
                        variant="p"
                    >
                        商务咨询
                    </Typography>
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  width="70%"
                >
                    <Box
                      flex="1"
                      sx={{
                        borderBottom:'1px solid rgba(255, 255, 255, 0.1)',
                        display:'flex',
                        justifyContent:'center',
                      }}
                    >
                        <Typography
                            color="text.primary"
                            variant="p"
                            display="flex"
                            alignItems="center" 
                        >
                            苏州 工业园区矩子大厦8层<br></br>
                            上海 宝山区淞宝路155弄9号
                        </Typography>
                    </Box>

                    <Box
                      flex="1"
                      display="flex"
                      justifyContent="space-evenly"
                      alignItems="center" 
                    >
                        <IconButton
                          sx={{
                            width:'2em',
                            height:'2em',
                          }}
                        >
                            <AlarmIcon />
                        </IconButton>

                        <IconButton
                            sx={{
                            width:'2em',
                            height:'2em',
                            }}
                        >
                            <AlarmIcon />
                        </IconButton>

                        <IconButton
                            sx={{
                            width:'2em',
                            height:'2em',
                            }}
                        >
                            <AlarmIcon />
                        </IconButton>

                        <IconButton
                            sx={{
                            width:'2em',
                            height:'2em',
                            }}
                        >
                            <AlarmIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>

            <Snackbar
                open={openError}
                autoHideDuration={2500}
                onClose={handleErrorClose}
                message="抱歉！"
                anchorOrigin={{vertical: 'top', horizontal:'center'}}
                key={'top' + 'center'}
            >

                <Alert
                    severity="error"
                    variant="filled"
                >
                    抱歉！您输入的邮箱格式不正确！
                </Alert>
            </Snackbar>
            <Snackbar
                open={openSuccess}
                autoHideDuration={2500}
                onClose={handleSuccessClose}
                message=""
                anchorOrigin={{vertical: 'top', horizontal:'center'}}
                key={'top1' + 'center1'}
            >
                <Alert
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    邮件发送成功!
                </Alert>
            </Snackbar>
            <Snackbar
                open={openWarning}
                autoHideDuration={3000}
                onClose={handleWarningClose}
                message=""
                anchorOrigin={{vertical: 'top', horizontal:'center'}}
                key={'top2' + 'center2'}
            >
                <Alert
                    severity="warning"
                    variant="filled"
                >
                    网络连接故障！请稍候再试！
                </Alert>
            </Snackbar>
            <Snackbar
                open={openFrequent}
                autoHideDuration={3000}
                onClose={handleFrequentClose}
                message=""
                anchorOrigin={{vertical: 'top', horizontal:'center'}}
                key={'top3' + 'center3'}
            >
                <Alert
                    severity="warning"
                    variant="filled"
                >
                    邮件发送成功后，请不要频繁发送，两次间隔30秒!
                </Alert>
            </Snackbar>
        </Box>
    );
};
    
export default Contact;