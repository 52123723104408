function isscroll(dom,flag) {
    // 页面是否启用不可下拉
    if(flag) {
        dom.addEventListener('touchmove',function (event) {
            event.returnValue = true;  // 允许页面下拉的属性
        }, { passive: false });
        return;
    }
    let startY
    dom.addEventListener('touchstart', (e) => {
        startY = e.touches[0].pageY;
    }, { passive: false });

    dom.addEventListener('touchmove', handler, { passive: false });

    function handler(e) {
        const moveY = e.touches[0].pageY;
        // scrollTop是盒子内容被滚动条卷去的头部的高度
        const top = dom.scrollTop;
        // clientHeight获取页面的可视区宽高
        const ch = dom.clientHeight;
        // scrollWidth与scrollHeight是盒子内容的真实的宽度和高度。与和盒子大小无关，仅仅与盒子的内容有关系。
        const sh = dom.scrollHeight;
        if (!isChildTarget(e.target, dom)) {
            e.preventDefault();
            // top === 0 && moveY > startY  页面没有滚动且手指下滑
            // top + ch === sh && moveY < startY  页面可滑动的地方滑动到了最底部且手指上滑
        } else if ((top === 0 && moveY > startY) || (top + ch === sh && moveY < startY)) {
            // alert(1)
            e.preventDefault();
        }
    }
}
function isChildTarget(child, parent, justChild = false) {

    // justChild为true则只判断是否为子元素，若为false则判断是否为本身或者子元素 默认为false
    let parentNode;
    if (justChild) {
        parentNode = child.parentNode;
    } else {
        parentNode = child;
    }

    if (child && parent) {
        while (parentNode) {
            if (parent === parentNode) {
                return true;
            }
            parentNode = parentNode.parentNode;
        }
    }
    return false;
}

export default isscroll;