import {useEffect, useState,useRef } from 'react'
import logo from './logo.svg';
import './App.css';
import Sliding from './Sliding';
import Nav from './Nav';
import Footer from './Footer';
import Work from './Work';
import Home from './homeComponents/Home';
import Serve from './homeComponents/HomeSection_Three';
import Team from './homeComponents/HomeSection_Two';
import Contact from './homeComponents/HomeSection_Four';
import WorkPAGE from './WorkPAGE';
import Pop from './pop';
import { ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import theme from './theme'
import BClogo from './BClogo';
import {useMyContext } from './Context';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap'
import { useRoutes } from "react-router-dom";
import routes from "./routes";

function App() {
    //根据路由表生成对应的路由规则
  const element = useRoutes(routes);

  const context = useMyContext();
  const containerRef = useRef();

  useEffect(() => {
    const simulateProgress = () => {
      const interval = setInterval(() => {
        context.set.setProgress(prevProgress => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            context.set.setIsLoading(false);
            return 100;
          } else {
            return prevProgress + 10; // 这里可以根据实际情况调整进度增加的速度
          }
        });
      }, 300); // 这里可以调整更新进度的间隔时间
    };

    simulateProgress();


    return ()=>{};
  }, []);

// useGSAP(()=>{
//   if(context.state.MoveCompleted){
//     gsap.to(containerRef.current.style,{duration:1,opacity:1})
//   }
  
// },{dependencies: [context.state.MoveCompleted], scope:containerRef })

  return (
  <ThemeProvider theme={theme}>
    {/* <Box> */}
      {/*
      <Loda />
      <Footer /> */}
      {/* <Work /> */}
      {/* <Serve /> */}
      {/* <Contact /> */}
      {/* <About /> */}
      {/* <WorkPAGE /> */}
      {/* <Team /> */}
     

    <Box
      sx={{
        position:'fixed',
        backgroundColor:'background.default',
        height:'100%',
        width:'100%'
      }}
    >
      {context.state.isLoading ? (
      <Box 
        sx={{
          position:'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex:'999',
          width:"auto",
          height:"10vh",
        }}
        progress={context.state.progress}
      >
        <BClogo color="#ffffff" animationType="type1"/>
      </Box>):(
      <>

       { !context.state.MoveCompleted && (
       <Box ref={context.ref.bcLogoRef}
             sx={{
               position:'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -50%)',
               zIndex:'999',
               width:"auto",
               height:"10vh",
               opacity:1,
             }}
        >
          <BClogo color="#ffffff" animationType="type2"/>
        </Box>
      )}
        {
          !context.state.MoveCompleted && (
              <>
                <Box
                    ref={containerRef}
                    sx={{
                      position:'fixed',
                      backgroundColor:'background.default',
                      opacity:1,
                      height:'100%',
                      width:'100%',
                      zIndex:'100',
                    }}>
                  <Sliding />
                </Box>
              </>
            )
        }


      { context.state.isNavShow && <Nav /> }
      {/* <Team /> */}
      {/* <Work /> */}
      {element}
      {/* {context.state.MoveCompleted && <Pop />} */}
      </>

      )}
    </Box>
  </ThemeProvider>
  );
}

export default App;
